import React, { Component } from "react";

import { Link, useNavigate } from "react-router-dom";
// import AuthVerify from "./common/auth-verify";
import EventBus from "../../common/EventBus";
import AuthService from "../../services/auth.service";
import Logo from "./../../assets/Logo JXB white.png";
import authHeader from "../../services/auth-header";
import axios from 'axios';
import $ from 'jquery';
import http from "../../services/http";

export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
            data: [],
            showPIC: false,
            showMGR: false,
            showMGRA: false,
            showDIR: false,
            showSM: false,
            showVR: false,
            showAdminBoard: false,
            currentUser: undefined,
        };
    }


    readNotif(id) {
        console.log(id);
        axios.put(http.getBaseURL() + `/notifications/${id}`, {}, { headers: authHeader() })
            .then(res => {
                console.log(res);
                console.log(res.data);

                const data = this.state.data.filter(item => item.id !== id);
                this.setState({ data });
            })

    }
    readAllNotif() {

        axios.put(http.getBaseURL() + `/notifications`, {}, { headers: authHeader() })
            .then(res => {
                console.log(res);
                console.log(res.data);
                window.location.reload();
            })

    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        axios.get(http.getBaseURL() + '/submissions/payments', { headers: authHeader() }).then(res => {

            this.setState({ data: res.data.data.notifications });
            console.log(res.data.data.notifications);
        });

        if (user) {
            this.setState({
                currentUser: user,
                showPIC: user.role.name.includes("PIC Bidang/Bagian"),
                showMGR: user.role.name.includes("Mgr Perbendaharaan"),
                showMGRA: user.role.name.includes("Mgr Akuntansi & Pajak"),
                showSM: user.role.name.includes("SM Keuangan"),
                showDIR: user.role.name.includes("Direksi"),
                showVR: user.role.name.includes("Verifikator"),
                showAdminBoard: user.role.name.includes("Administrator"),
            });
        }

        EventBus.on("logout", () => {
            this.logOut();
        });


    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }

    logOut() {
        AuthService.logout();
        this.setState({
            showPIC: false,
            showMGR: false,
            showMGRA: false,
            showDIR: false,
            showSM: false,
            showVR: false,
            showAdminBoard: false,
            currentUser: undefined,
        });
    }

    render() {
        const { currentUser, showPIC, showAdminBoard, showDIR, showMGR, showMGRA, showSM, showVR } = this.state;

        return (
            <>

                <header className="navbar navbar-expand-md navbar-dark d-print-none">
                    <div className="container-xl">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                            <a href="." style={{ fontSize: "15px" }}>
                                <span><img src={Logo} height={50} alt="JXB WHITE" /></span>
                                Payment Tracking System
                            </a>
                        </h1>
                        <div className="navbar-nav flex-row order-md-last">

                            <a href="?theme=dark" className="nav-link px-0 hide-theme-dark" title="Enable dark mode" data-bs-toggle="tooltip" data-bs-placement="bottom">

                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" /></svg>
                            </a>
                            <a href="?theme=light" className="nav-link px-0 hide-theme-light" title="Enable light mode" data-bs-toggle="tooltip" data-bs-placement="bottom">

                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="4" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
                            </a>
                            <div className="nav-item dropdown d-none d-md-flex me-3">
                                <a href="#" className="nav-link px-0" data-bs-toggle="dropdown" tabIndex="-1" aria-label="Show notifications">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
                                    {this.state.data.length > 0 &&
                                        <h2>
                                            <span className="badge bg-red">{this.state.data.length}</span>
                                        </h2>
                                    }
                                </a>
                                {this.state.data.length > 0 &&
                                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-card keep-open">
                                        <div className="card">
                                            <div className="card-body" style={{ overflow: "scroll", height: "300px" }}>

                                                {this.state.data.map((result, i, index) => {
                                                    return (

                                                        <div className="row" style={{ borderBottom: "1px solid grey" }}>
                                                            <div className="col-12">
                                                                <p style={{ fontSize: "10px", margin: "1rem 0 " }}>
                                                                    {result?.note} <span style={{ fontWeight: "bold" }}>{result?.Submission?.name} </span>
                                                                    <button onClick={() => this.readNotif(result.id)} className="btn btn-sm btn-secondary" style={{ borderRadius: "10px", fontSize: "10px" }}>Read</button>

                                                                </p>
                                                            </div>

                                                        </div>

                                                    )
                                                })}
                                                <div className="row">
                                                    <button onClick={this.readAllNotif} className="btn btn-sm btn-secondary" style={{ borderRadius: "10px", fontSize: "10px", width: "100%", margin: "1rem 0 " }}>Read All</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.data.length == 0 &&
                                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-card keep-open">
                                        <div className="card">
                                            <div className="card-body h-100 d-flex justify-content-center align-items-center" style={{ overflow: "scroll", height: "300px" }}>

                                                Notifikasi Kosong
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="nav-item dropdown">
                                <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                                    <span className="avatar avatar-sm" ><img
                                        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                                        alt="profile-img"
                                    /></span>
                                    <div className="d-none d-xl-block ps-2">
                                        <div>{currentUser?.name}</div>
                                        <div className="mt-1 small text-muted">{currentUser?.role?.name}</div>
                                    </div>
                                </a>

                                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                    <Link to={"/profile"} className="dropdown-item">Akun dan Profil</Link>
                                    <div className="dropdown-divider"></div>
                                    <a href="/login" className="dropdown-item" onClick={this.logOut}>
                                        LogOut
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="navbar-expand-md">
                    <div className="collapse navbar-collapse" id="navbar-menu">
                        <div className="navbar navbar-light">
                            <div className="container-xl">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"/home"} >
                                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="5 12 3 12 12 3 21 12 19 12" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
                                            </span>
                                            <span className="nav-link-title">
                                                Home
                                            </span>
                                        </Link>
                                    </li>


                                    {showAdminBoard && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/user-management"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0m-2 14v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2m1 -17.87a4 4 0 0 1 0 7.75m5 10.12v-2a4 4 0 0 0 -3 -3.85"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    User Management
                                                </span>
                                            </Link>
                                        </li>

                                    )}

                                    {showAdminBoard && (
                                        <>
                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
                                                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" /><line x1="12" y1="12" x2="20" y2="7.5" /><line x1="12" y1="12" x2="12" y2="21" /><line x1="12" y1="12" x2="4" y2="7.5" /><line x1="16" y1="5.25" x2="8" y2="9.75" /></svg>
                                                    </span>
                                                    <span class="nav-link-title">
                                                        Konfigurasi Lampiran Formulir
                                                    </span>
                                                </a>
                                                <div class="dropdown-menu">
                                                    <div class="dropdown-menu-columns">
                                                        <div class="dropdown-menu-column">
                                                            <Link className="dropdown-item" to={"/attachment-assignment"} >
                                                                <span className="nav-link-title">
                                                                    Konfigurasi Lampiran Departemen
                                                                </span>
                                                            </Link>
                                                            <Link className="dropdown-item" to={"/document-type"} >
                                                                <span className="nav-link-title">
                                                                    Konfigurasi Tipe Dokumen
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    )}

                                    {showVR && (

                                        <>
                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
                                                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" /><line x1="12" y1="12" x2="20" y2="7.5" /><line x1="12" y1="12" x2="12" y2="21" /><line x1="12" y1="12" x2="4" y2="7.5" /><line x1="16" y1="5.25" x2="8" y2="9.75" /></svg>
                                                    </span>
                                                    <span class="nav-link-title">
                                                        Konfigurasi Lampiran Formulir
                                                    </span>
                                                </a>
                                                <div class="dropdown-menu">
                                                    <div class="dropdown-menu-columns">
                                                        <div class="dropdown-menu-column">
                                                            <Link className="dropdown-item" to={"/attachment-assignment"} >
                                                                <span className="nav-link-title">
                                                                    Konfigurasi Lampiran Departemen
                                                                </span>
                                                            </Link>
                                                            <Link className="dropdown-item" to={"/document-type"} >
                                                                <span className="nav-link-title">
                                                                    Konfigurasi Tipe Dokumen
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    )}

                                    {showPIC && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/payment-submission"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3zm0 2l18 0m-14 5l.01 0m3.99 0l2 0"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    Pengajuan Pembayaran Tagihan
                                                </span>
                                            </Link>
                                        </li>
                                    )}



                                    {showDIR && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"approve-payment-submission"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3zm0 2l18 0m-14 5l.01 0m3.99 0l2 0"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    Pengajuan Pembayaran Tagihan
                                                </span>
                                            </Link>
                                        </li>
                                    )}
                                    {showDIR && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/approve-bill-submission"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3zm0 2l18 0m-14 5l.01 0m3.99 0l2 0"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    Pengajuan Penagihan
                                                </span>
                                            </Link>
                                        </li>
                                    )}
                                    {showVR && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/approve-payment-submission"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3zm0 2l18 0m-14 5l.01 0m3.99 0l2 0"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    Persetujuan Pembayaran Tagihan
                                                </span>
                                            </Link>
                                        </li>
                                    )}
                                    {showMGR && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/approve-payment-submission"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3zm0 2l18 0m-14 5l.01 0m3.99 0l2 0"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    Persetujuan Pembayaran Tagihan
                                                </span>
                                            </Link>
                                        </li>
                                    )}
                                    {showSM && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/approve-payment-submission"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3zm0 2l18 0m-14 5l.01 0m3.99 0l2 0"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    Persetujuan Pembayaran Tagihan
                                                </span>
                                            </Link>
                                        </li>
                                    )}
                                    {showSM && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/approve-bill-submission"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3zm0 2l18 0m-14 5l.01 0m3.99 0l2 0"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    Persetujuan Pengajuan Penagihan
                                                </span>
                                            </Link>
                                        </li>
                                    )}
                                    {showMGRA && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/approve-bill-submission"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3zm0 2l18 0m-14 5l.01 0m3.99 0l2 0"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    Persetujuan Pengajuan Penagihan
                                                </span>
                                            </Link>
                                        </li>
                                    )}
                                    {showPIC && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/bill-submission"} >
                                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-receipt" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path>
                                                    </svg>
                                                </span>
                                                <span className="nav-link-title">
                                                    Pengajuan Penagihan
                                                </span>
                                            </Link>
                                        </li>
                                    )}
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false" >
                                            <span class="nav-link-icon d-md-none d-lg-inline-block">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-history" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M12 8l0 4l2 2m-10.95 -3a9 9 0 1 1 .5 4m-.5 5v-5h5"></path>
                                                </svg>                                            </span>
                                            <span class="nav-link-title">
                                                Daftar Riwayat
                                            </span>
                                        </a>
                                        <div class="dropdown-menu">
                                            <div class="dropdown-menu-columns">
                                                <div class="dropdown-menu-column">
                                                    {showPIC && (
                                                        <Link className="dropdown-item" to={"/payment-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Pembayaran Tagihan
                                                            </span>
                                                        </Link>
                                                    )}
                                                    {showMGR && (
                                                        <Link className="dropdown-item" to={"/payment-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Pembayaran Tagihan
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {showMGR && (
                                                        <Link className="dropdown-item" to={"/bill-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Penagihan
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {showVR && (
                                                        <Link className="dropdown-item" to={"/payment-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Pembayaran Tagihan
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {showSM && (
                                                        <Link className="dropdown-item" to={"/payment-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Pembayaran Tagihan
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {showSM && (
                                                        <Link className="dropdown-item" to={"/bill-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Penagihan
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {showPIC && (
                                                        <Link className="dropdown-item" to={"/bill-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Penagihan
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {showMGRA && (
                                                        <Link className="dropdown-item" to={"/bill-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Penagihan
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {showMGRA && (
                                                        <Link className="dropdown-item" to={"/payment-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Pembayaran Tagihan
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {showDIR && (
                                                        <Link className="dropdown-item" to={"/bill-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Penagihan
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {showDIR && (
                                                        <Link className="dropdown-item" to={"/payment-histories"} >
                                                            <span className="nav-link-title">
                                                                Riwayat Pembayaran Tagihan
                                                            </span>
                                                        </Link>
                                                    )}


                                                </div>
                                            </div>
                                        </div>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            </>




        );
    }
}