import React, { Component } from "react";
//jQuery libraries
import 'jquery/dist/jquery.min.js';
import authHeader from "../../../services/auth-header";
import axios from 'axios';
import AuthService from "../../../services/auth.service";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import swal from 'sweetalert';
import JSZip from "jszip";
import $ from 'jquery';
import moment from "moment";
import http from "../../../services/http";
window.JSZip = JSZip;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (value == null) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
export default class AttachDoc extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.handleChangePayment = this.handleChangePayment.bind(this);
        this.handleSubmitCreate = this.handleSubmitCreate.bind(this);
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
        this.state = {
            data: [],
            detail: [],
            depts: [],
            paymentType: [],
            docType: [],
            paymentTypeId: '',
            departementId: '',
            documentTypeId: '',
            isRequired: '',
            isActive: '',
            message: '',
            checkForm: ''
        }
    }
    logIdEdit = (id, cid) => {

        this.idEdit = id;
        console.log(this.idEdit);
        const idForEdit = this.idEdit;
        axios.get(http.getBaseURL() + '/configurations/attachments/' + idForEdit, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data });
            console.log(res.data.data);
        });
        this.setState({
            checkForm: cid
        })
        axios.get(http.getBaseURL() + '/references/departements', { headers: authHeader() }).then(res => {
            this.setState({ depts: res.data.data });
        });
        axios.get(http.getBaseURL() + '/references/payment-types', { headers: authHeader() }).then(res => {
            this.setState({ paymentType: res.data.data });
        });
        axios.get(http.getBaseURL() + '/configurations/doctypes', { headers: authHeader() }).then(res => {
            this.setState({ docType: res.data.data });
            console.log(res.data.data)
        });
    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleChangePayment = (e) => {
        this.setState({
            checkForm: e.target.value
        })
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmitCreate = event => {
        event.preventDefault();
        this.setState({
            message: "",
        });

        const config = {
            headers: {
                "Authorization": user.accessToken,
            }
        };
        const url = http.getBaseURL() + "/configurations/attachments";

        if (this.state.checkForm == 1) {
            const data = {
                paymentTypeId: this.state.paymentTypeId,
                departementId: this.state.departementId,
                documentTypeId: this.state.documentTypeId,
                isRequired: this.state.isRequired,
                isActive: this.state.isActive,
            }
            axios.post(url, data, config)
                .then(res => {
                    console.log(res);
                    console.log(res.data.status);
                    window.location = "/attachment-assignment"
                })
                .catch((error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        message: resMessage
                    });
                });
        } else {
            const data = {
                paymentTypeId: this.state.paymentTypeId,
                documentTypeId: this.state.documentTypeId,
                isRequired: this.state.isRequired,
                isActive: this.state.isActive,
            }
            axios.post(url, data, config)
                .then(res => {
                    console.log(res);
                    console.log(res.data.status);
                    window.location = "/attachment-assignment"
                })
                .catch((error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        message: resMessage
                    });
                });
        }




    }

    handleSubmitEdit = event => {
        this.setState({
            message: "",
        });
        const idEditShow = this.idEdit;
        event.preventDefault();

        const config = {
            headers: {
                "Authorization": user.accessToken,
            }
        };
        const url = http.getBaseURL() + "/configurations/attachments/" + idEditShow;
        if (this.state.checkForm == 1) {
            const data = {
                paymentTypeId: this.state.paymentTypeId,
                departementId: this.state.departementId,
                documentTypeId: this.state.documentTypeId,
                isRequired: this.state.isRequired,
                isActive: this.state.isActive,
            }
            axios.put(url, data, config)
                .then(res => {
                    console.log(res);
                    console.log(res.data.status);
                    window.location = "/attachment-assignment"
                })
                .catch((error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        message: resMessage
                    });
                });
        } else {
            const data = {
                paymentTypeId: this.state.paymentTypeId,
                documentTypeId: this.state.documentTypeId,
                isRequired: this.state.isRequired,
                isActive: this.state.isActive,
            }
            axios.put(url, data, config)
                .then(res => {
                    console.log(res);
                    console.log(res.data.status);
                    window.location = "/attachment-assignment"
                })
                .catch((error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        message: resMessage
                    });
                });
        }
    }
    createReference() {
        axios.get(http.getBaseURL() + '/references/departements', { headers: authHeader() }).then(res => {
            this.setState({ depts: res.data.data });
        });
        axios.get(http.getBaseURL() + '/references/payment-types', { headers: authHeader() }).then(res => {
            this.setState({ paymentType: res.data.data });
        });
        axios.get(http.getBaseURL() + '/configurations/doctypes', { headers: authHeader() }).then(res => {
            this.setState({ docType: res.data.data });
            console.log(res.data.data)
        });
    }

    deleteRow(id, e) {

        swal({
            title: "Apakah Anda Yakin?",
            text: "Jika anda menghapus data ini tidak akan bisa dikembalikan!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    swal("Data Anda berhasil dihapus", {
                        icon: "success",
                    });
                    axios.delete(http.getBaseURL() + `/configurations/attachments/${id}`, { headers: authHeader() })
                        .then(res => {
                            console.log(res);
                            console.log(res.data);

                            const data = this.state.data.filter(item => item.id !== id);
                            this.setState({ data });
                        })
                } else {
                    swal("Penghapusan Data Dibatalkan!");
                }
            });


    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        axios.get(http.getBaseURL() + '/configurations/attachments', { headers: authHeader() }).then(res => {
            this.setState({ data: res.data.data });
            console.log(res.data.data);
        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    render() {

        return (
            <>
                <div className="page-wrapper">
                    <div className="container-fluid" >
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">

                                    <div className="page-pretitle">
                                        Konfigurasi Lampiran
                                    </div>
                                    <h2 className="page-title">
                                        Konfigurasi Lampiran Berkas
                                    </h2>
                                </div>

                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">

                                        <a href="#" className="btn btn-primary d-none d-sm-inline-block" data-bs-toggle="modal" data-bs-target="#modal-attach-submission" onClick={() => this.createReference()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                            Tambahkan Lampiran
                                        </a>
                                        <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-attach-submission" aria-label="Create new report">

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                        </a>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-fluid" style={{ padding: "10px" }}>
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Daftar lampiran</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3" style={{ overflow: "scroll" }}>
                                            <table id="example" class="table table-hover table-bordered" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Tipe Pembayaran</th>
                                                        <th>Tipe Dokumen</th>
                                                        <th>Lampiran untuk Departemen</th>
                                                        <th>Dibutuhkan</th>
                                                        <th>Status</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.map((result, i, index) => {
                                                        return (
                                                            <tr>
                                                                <td key={i}>{result.PaymentType.name}</td>
                                                                <td>{result.DocumentType.name}</td>
                                                                <td>{result.Departement?.name}</td>
                                                                <td>
                                                                    {result.isRequired == true ? (
                                                                        "Mandatori"
                                                                    ) : (
                                                                        "Tidak Mandatori"
                                                                    )}

                                                                </td>
                                                                <td>
                                                                    {result.isActive == true ? (
                                                                        "Aktif"
                                                                    ) : (
                                                                        "Tidak Aktif"
                                                                    )}
                                                                </td>

                                                                <td className="text-center">

                                                                    <div>
                                                                        <button style={{ margin: "0 10px" }} className="btn btn-warning btn-sm  d-sm-inline-block" onClick={() => this.logIdEdit(result.id, result.PaymentType.id)} data-bs-toggle="modal" data-bs-target="#modal-attach-edit">
                                                                            <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ballpen" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M14 6l7 7l-4 4m-11.172 1.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4zm-1.828 1.828l1.768 -1.768"></path>
                                                                            </svg>
                                                                        </button>

                                                                        <button onClick={(e) => this.deleteRow(result.id, e)} href="#" style={{ margin: "0 10px" }} className="btn btn-danger btn-sm  d-sm-inline-block">
                                                                            <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-minus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0m6 0l6 0"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal modal-blur fade" id="modal-attach-submission" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Tambahkan Lampiran Untuk Departemen</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.handleSubmitCreate} className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <div className="form-label">Pilih Tipe Pembayaran</div>

                                                        <select
                                                            className="form-control"
                                                            name="paymentTypeId"
                                                            onChange={this.handleChangePayment}
                                                            validations={[required]}
                                                        >
                                                            <option value={null}>Pilih Tipe Pembayaran</option>
                                                            {this.state.paymentType.map((result) => {
                                                                return (
                                                                    <option value={result.id}>{result.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    {this.state.checkForm == 1 ?

                                                        <div className="mb-3">
                                                            <div className="form-label">Pilih Departemen</div>
                                                            <select
                                                                className="form-control"
                                                                name="departementId"
                                                                onChange={this.handleChange}
                                                                validations={[required]}
                                                            >
                                                                <option>Pilih Departemen</option>
                                                                {this.state.depts.map((result) => {
                                                                    return (
                                                                        <option value={result.id}>{result.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        : null

                                                    }
                                                    <div className="mb-3">
                                                        <div className="form-label">Pilih Tipe Dokumen yang Dilampirkan</div>
                                                        <select
                                                            className="form-control"
                                                            name="documentTypeId"
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                        >
                                                            <option value={null}>Pilih Tipe Dokumen</option>
                                                            {this.state.docType.map((result) => {
                                                                return (
                                                                    <option value={result.id}>{result.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Mandatori</label>
                                                        <select
                                                            className="form-control"
                                                            name="isRequired"
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                        >
                                                            <option value={null}>Apakah Mandatori</option>

                                                            <option value={1}>Ya</option>
                                                            <option value={0}>Tidak</option>

                                                        </select>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Status</label>
                                                        <select
                                                            className="form-control"
                                                            name="isActive"
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                        >
                                                            <option value={null}>Pilih Status</option>

                                                            <option value={1}>Aktif</option>
                                                            <option value={0}>Non-Aktif</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {this.state.message && (
                                                    <div className="form-group">
                                                        <div className="alert alert-danger" role="alert">
                                                            {this.state.message}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>



                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Batalkan
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal modal-blur fade" id="modal-attach-edit" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Tambahkan Lampiran Untuk Departemen</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.handleSubmitEdit} className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <div className="form-label">Pilih Tipe Pembayaran</div>

                                                        <select
                                                            className="form-control"
                                                            name="paymentTypeId"
                                                            onChange={this.handleChangePayment}
                                                            validations={[required]}

                                                        >
                                                            <option value={this.state.detail?.PaymentType?.id}>{this.state.detail?.PaymentType?.name}</option>
                                                            {this.state.paymentType.map((result) => {
                                                                return (
                                                                    <option value={result.id}>{result.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    {this.state.checkForm == 1 ?

                                                        <div className="mb-3">
                                                            <div className="form-label">Pilih Departemen</div>
                                                            <select
                                                                className="form-control"
                                                                name="departementId"
                                                                onChange={this.handleChange}
                                                                validations={[required]}
                                                            >
                                                                <option value={this.state.detail?.Departement?.id}>{this.state.detail?.Departement?.name}</option>
                                                                {this.state.depts.map((result) => {
                                                                    return (
                                                                        <option value={result.id}>{result.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        : null

                                                    }
                                                    <div className="mb-3">
                                                        <div className="form-label">Pilih Tipe Dokumen yang Dilampirkan</div>
                                                        <select
                                                            className="form-control"
                                                            name="documentTypeId"
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                        >
                                                            <option value={this.state.detail?.DocumentType?.id}>{this.state.detail?.DocumentType?.name}</option>
                                                            {this.state.docType.map((result) => {
                                                                return (
                                                                    <option value={result.id}>{result.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Mandatori</label>
                                                        <select
                                                            className="form-control"
                                                            name="isRequired"
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                        >
                                                            {this.state.detail?.isRequired == true ? (
                                                                <option value={1}>Ya</option>
                                                            ) : (
                                                                <option value={0}>Tidak</option>
                                                            )}

                                                            <option value={1}>Ya</option>
                                                            <option value={0}>Tidak</option>

                                                        </select>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Status</label>
                                                        <select
                                                            className="form-control"
                                                            name="isActive"
                                                            onChange={this.handleChange}
                                                            validations={[required]}
                                                        >
                                                            {this.state.detail.isActive == true ? (
                                                                <option value={1}>Aktif</option>
                                                            ) : (
                                                                <option value={0}>Non-Aktif</option>
                                                            )}

                                                            <option value={1}>Aktif</option>
                                                            <option value={0}>Non-Aktif</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {this.state.message && (
                                                    <div className="form-group">
                                                        <div className="alert alert-danger" role="alert">
                                                            {this.state.message}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>



                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Batalkan
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}