import React, { Component } from "react";
//jQuery libraries
import 'jquery/dist/jquery.min.js';
import authHeader from "../../../services/auth-header";
import axios from 'axios';
import AuthService from "../../../services/auth.service";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import swal from 'sweetalert';
import JSZip from "jszip";
import $ from 'jquery';
import moment from "moment";
import { NumericFormat } from 'react-number-format';
import http from "../../../services/http";
window.JSZip = JSZip;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default class BillHistories extends Component {
    constructor(props) {
        super(props)
        this.handleOnChangeCreateFile = this.handleOnChangeCreateFile.bind(this);
        this.handleUpdateSPM = this.handleUpdateSPM.bind(this);
        this.handleUpdateDocBill = this.handleUpdateDocBill.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);



        this.state = {
            data: [],
            showMGR: false,
            showPIC: false,
            detail: [],
            spmUpload: '',
            proofBill: '',
            paymentType: '',
            startDate: '',
            endDate: '',
        }
    }

    handleFilter = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        event.preventDefault();

        const config = {
            headers: {
                "Authorization": user.accessToken,
            },
            params: {
                paymentType: 2,
                startDate: this.state.startDate,
                endDate: this.state.endDate,

            }
        };
        axios.get(http.getBaseURL() + '/histories/bills', config).then(res => {
            this.setState({ data: res.data.data.submissions });
            console.log(res.data.data.submissions);
        });

    }

    handleOnChangeCreateFile = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }
    handleUpdateDocBill = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();

        const config = {
            headers: {
                "Authorization": user.accessToken,
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow + "/recall";
        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Pembatalan Upload Dokumen Bukti Pembayaran",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Pembatalan Upload Dokumen Bukti Pembayaran Berhasil dilakukan", {
                        icon: "success",
                    });
                    axios.put(url, {}, {
                        headers: {
                            "Authorization": user.accessToken,
                        }
                    })
                        .then(res => {
                            console.log(res);
                            console.log(res.data.status);
                            window.location = "/bill-histories" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Batalkan Recall");
                }
            });


    }
    handleUpdateSPM = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const config = {
            headers: {
                "Authorization": user.accessToken,

            }
        };
        const url = http.getBaseURL() + "/submissions/payments/" + IdStatusNow + "/recall";

        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Pembatalan Upload SPM",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Pembatalan Upload SPM Berhasil dilakukan", {
                        icon: "success",
                    });
                    axios.put(url, {}, {
                        headers: {
                            "Authorization": user.accessToken,
                        }
                    })
                        .then(res => {
                            console.log(res);
                            console.log(res.data.status);
                            window.location = "/histories" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Batalkan Recall");
                }
            });



    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    detailRowBill(id, e) {
        this.idStatus = id;
        const IdStatusNow = this.idStatus;
        console.log(IdStatusNow)
        axios.get(http.getBaseURL() + '/submissions/payments/' + IdStatusNow, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data.submission });
            console.log(res.data.data.submission);
        });
    }
    detailRowBill(id, e) {
        this.idStatus = id;
        const IdStatusNow = this.idStatus;
        console.log(IdStatusNow)
        axios.get(http.getBaseURL() + '/submissions/bills/' + IdStatusNow, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data.submission });
            console.log(res.data.data.submission);
        });
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                showMGR: user.role.name.includes("Perbendaharaan"),
                showPIC: user.role.name.includes("PIC Bidang/Bagian"),
            });
        }
        axios.get(http.getBaseURL() + '/histories/bills', { headers: authHeader() }).then(res => {
            this.setState({ data: res.data.data.submissions });
            console.log(res.data.data.submissions);
        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {

                $('#example-history').DataTable({
                    pagingType: "full_numbers",
                    pageLength: 20,
                    processing: true,
                    dom: "Bfrtip",
                    select: {
                        style: "single",
                    },
                    buttons: [
                        {
                            extend: "pageLength",
                            className: "btn btn-primary bg-primary",
                        },
                        {
                            extend: "copy",
                            className: "btn btn-primary bg-primary",
                        },
                        {
                            extend: "excel",
                            className: "btn btn-primary bg-primary",
                        },
                        {
                            extend: "pdf",
                            className: "btn btn-primary bg-primary",
                        },
                        {
                            extend: "print",
                            customize: function (win) {
                                $(win.document.body).css("font-size", "10pt");
                                $(win.document.body)
                                    .find("table")
                                    .addClass("compact")
                                    .css("font-size", "inherit");
                            },
                            className: "btn btn-primary bg-primary",
                        },
                    ],

                    lengthMenu: [
                        [10, 20, 30, 50, -1],
                        [10, 20, 30, 50, "All"],
                    ],
                    columnDefs: [
                        {
                            targets: 0,
                            render: function (data, type, row, meta) {
                                return type === "export" ? meta.row + 1 : data;
                            },
                        },
                    ],
                });
            }, 1000);
        });
    }
    render() {
        const { currentUser, showMGR, showPIC } = this.state;

        return (
            <>
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">

                                    <div className="page-pretitle">
                                        Riwayat
                                    </div>
                                    <h2 className="page-title">
                                        Semua Riwayat Penagihan
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-fuid" style={{ padding: "0 24px" }}>
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Daftar Penagihan</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3" style={{ overflow: "scroll" }}>
                                            <form onSubmit={this.handleFilter}>
                                                <div className="row">

                                                    <div className="col-lg-4 col-md-12 col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Tanggal mulai</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="startDate"
                                                                placeholder="Input Due Date"
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-12 col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Tanggal Akhir</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="endDate"
                                                                placeholder="Input Due Date"
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-md-12 col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Filter</label>
                                                            <button type="submit" className="btn btn-primary ms-auto w-100" >Kirim</button>

                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                            <table id="example-history" class="table table-hover table-bordered" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Tujuan Penagihan</th>
                                                        <th>Aktivitas</th>
                                                        <th>Tanggal Invoice</th>
                                                        <th>Nominal Tagihan</th>
                                                        <th>Diajukan Oleh</th>
                                                        <th>Diajukan pada</th>
                                                        <th>Selesai pada</th>
                                                        <th>Aksi</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.map((result, i) => {
                                                        return (
                                                            <tr>
                                                                <td key={i}>{result.invDestination}</td>
                                                                <td>{result.invActivity}</td>
                                                                <td>{result.invDate}</td>
                                                                <td>
                                                                    <NumericFormat value={result.invValue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                </td>
                                                                <td>{result.User.name}</td>
                                                                <td>{result.createdAt}</td>
                                                                <td>{result.updatedAt}</td>

                                                                <td className="text-center">

                                                                    <div>
                                                                        <button style={{ margin: "0 10px" }} className="btn btn-info btn-sm  d-sm-inline-block" onClick={(e) => this.detailRowBill(result.id, e)} data-bs-toggle="modal" data-bs-target="#modal-history-detail">
                                                                            <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>

                                                                </td>


                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal modal-blur fade " id="modal-history-detail" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Detail Pembayaran</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="card">
                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="row">
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Status Pengajuan Penagihan</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail?.Status?.name}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Diajukan Oleh</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail?.User?.name}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Tujuan Penagihan</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail.invDestination}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Aktivitas</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail.invActivity}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Tanggal Invoice</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="dueDate"
                                                            value={this.state.detail.invDate}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Nominal Tagihan</label>
                                                        <NumericFormat style={{ backgroundColor: "#f1f5f9", color: "#626976" }} className="form-control" disabled value={this.state.detail.invValue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Diajukan pada</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="letterDate"
                                                            placeholder="Input Date"
                                                            value={this.state.detail.createdAt}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Selesai pada</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="letterDate"
                                                            placeholder="Input Date"
                                                            value={this.state.detail.updatedAt}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Tagihan yang Dibayarkan</label>
                                                        <NumericFormat style={{ backgroundColor: "#f1f5f9", color: "#626976" }} className="form-control" disabled value={this.state.detail.invValuePaid} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="mb-3 col-lg-12">
                                                        <label className="form-label">Catatan</label>
                                                        <textarea
                                                            rows="5"
                                                            type="text"
                                                            className="form-control"
                                                            name="description"
                                                            value={this.state.detail.note}
                                                            disabled
                                                        >
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {this.state.detail?.Documents?.map((result) => {
                                                        return (

                                                            <div className="mb-3 col-lg-6 col-md-12 col-sm-12">
                                                                <label className="form-label">{result.DocType?.name} |
                                                                    {result.filename.length > 30 ?
                                                                        `${result.filename.substring(0, 30)}...` : result.filename
                                                                    }
                                                                </label>
                                                                <a style={{ width: "100%" }} className="btn btn-primary  d-sm-inline-block" href={result.path} target="_blank">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                                                        <path d="M7 11l5 5l5 -5"></path>
                                                                        <path d="M12 4l0 12"></path>
                                                                    </svg>
                                                                    Download File
                                                                </a>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">

                                                    {showPIC && (
                                                        <div>
                                                            <form onSubmit={this.handleUpdateDocBill} >
                                                                <div className="container mt-3">
                                                                    <div className="col-xl-12">
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-xl-12">
                                                                                <div className="mb-3 col-12">
                                                                                    <label className="form-label">Silakan Klik Tombol Berikut untuk Membatalkan Bukti Pembayaran</label>
                                                                                    <button type="submit" className="btn btn-primary ms-auto" >Lakukan Penggantian Bukti Pembayaran</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    )}


                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <label className="form-label">Riwayat Pengajuan</label>
                                                </div>
                                                <div className="card-body">
                                                    <table id="example" class="table table-hover table-bordered" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Di Approve Oleh</th>
                                                                <th>Status Approval</th>
                                                                <th>Catatan</th>
                                                                <th>Waktu</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.detail?.Approval?.map((result) => {
                                                                return (

                                                                    <tr>
                                                                        <td>{result.id}</td>
                                                                        <td>{result.ApprovalType.name}</td>
                                                                        <td>
                                                                            {result.approval === true ? (
                                                                                <p>Disetujui</p>
                                                                            ) : (
                                                                                <p>Ditolak</p>
                                                                            )}

                                                                        </td>
                                                                        <td>{result.note}</td>
                                                                        <td>
                                                                            <p>

                                                                                {moment(result.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                                            </p>
                                                                        </td>

                                                                    </tr>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Kembali
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}