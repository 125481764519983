import React, { Component } from 'react';
import axios from 'axios';
import authHeader from "../../../services/auth-header";
import http from '../../../services/http';
import { Link } from "react-router-dom";
const user = JSON.parse(localStorage.getItem('user'));

export default class UserProfile extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
        this.state = {
            name: '',
            email: '',
            password: '',
            repassword: '',
            profile: [],
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    handleSubmit = event => {
        event.preventDefault();
        const data = {
            name: this.state.name,
            email: this.state.email,
        }
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/users";
        axios.put(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/profile    " //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleSubmitPassword = event => {
        event.preventDefault();
        const data = {
            password: this.state.password,
            repassword: this.state.repassword,
        }
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/users/change-password";
        axios.put(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/profile    " //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
            });
    }

    componentDidMount() {
        axios.get(http.getBaseURL() + '/users/profile', { headers: authHeader() }).then(res => {
            //Storing users detail in state array object
            this.setState({ profile: res.data.data.user });
            console.log(res.data.data.user);
        });

    }
    render() {
        const { profile } = this.state;
        return (
            <>
                <div className="page-wrapper">
                    <div className="container-xl">
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">


                                    <h2 className="page-title">
                                        Profile
                                    </h2>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-xl">
                            <div className="row row-deck row-cards">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">My Profile</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-md-6 col-xl-12">

                                                            <div className="mb-3">
                                                                <label className="form-label">Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"

                                                                    placeholder="Input Name"

                                                                    value={this.state.profile.name}
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label">Username</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"

                                                                    placeholder="Input Username"

                                                                    value={this.state.profile.username}
                                                                    disabled
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <label className="form-label">Email</label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"

                                                                    placeholder="Input Username"

                                                                    value={this.state.profile.email}
                                                                    disabled
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <label className="form-label">Status Active</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"

                                                                    placeholder="Input Username"

                                                                    value={this.state.profile.active}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='card-footer text-end'>
                                            <div className='d-flex'>

                                                <a href="#" style={{ margin: "5px" }} class="btn btn-primary btn-sm d-sm-inline-block " data-bs-toggle="modal" data-bs-target="#modal-profile">
                                                    Change Profile
                                                </a>


                                                <a href="#" style={{ margin: "5px" }} class="btn btn-info btn-sm  d-sm-inline-block " data-bs-toggle="modal" data-bs-target="#modal-password">
                                                    Change Password
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal modal-blur fade" id="modal-profile" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Change Profile</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.handleSubmit} className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            placeholder="Input Name"
                                                            onChange={this.handleChange}
                                                            defaultValue={profile.name}

                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            placeholder="Input Email"
                                                            onChange={this.handleChange}
                                                            defaultValue={profile.email}

                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>



                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Cancel
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal modal-blur fade" id="modal-password" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Change Password</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.handleSubmitPassword} className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="password"
                                                            placeholder="Input New Password"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">repassword</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="repassword"
                                                            placeholder="Retype New Password"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>



                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Cancel
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}
