import React, { Component } from 'react';
import axios from 'axios';
import authHeader from "../../../services/auth-header";
import { Link } from "react-router-dom";
import http from '../../../services/http';
const user = JSON.parse(localStorage.getItem('user'));

export default class RegisterUsers extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            name: [],
            email: [],
            username: [],
            roleId: [],
            depts: [],
            password: [],
            repassword: [],
            roles: [],
            checkForm: '',
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleChangeRole = (e) => {
        this.setState({
            checkForm: e.target.value
        })
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        const data = {
            name: this.state.name,
            email: this.state.email,
            username: this.state.username,
            roleId: this.state.roleId,
            password: this.state.password,
            repassword: this.state.repassword,
            departementId: this.state.departementId,
            active: 1,
        }
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/users";
        axios.post(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/user-management" //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
            });
    }

    componentDidMount() {
        axios.get(http.getBaseURL() + '/references/departements', { headers: authHeader() }).then(res => {
            this.setState({ depts: res.data.data });
        });
        axios.get(http.getBaseURL() + '/references/roles').then(res => {
            //Storing users detail in state array object
            this.setState({ roles: res.data.data });
            console.log(res.data.data);

        });

    }
    render() {

        return (
            <>
                <div className="page-wrapper">
                    <div className="container-xl">
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">

                                    <div className="page-pretitle">
                                        Users
                                    </div>
                                    <h2 className="page-title">
                                        Create New User Here
                                    </h2>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-xl">
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <form onSubmit={this.handleSubmit} className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Form elements</h4>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="row">
                                                            <div className="col-md-6 col-xl-12">

                                                                <div className="mb-3">
                                                                    <label className="form-label">Name</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="name"
                                                                        placeholder="Input Name"
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Email</label>
                                                                    <input
                                                                        type="email"
                                                                        className="form-control"
                                                                        name="email"
                                                                        placeholder="Input Name"
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Username</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="username"
                                                                        placeholder="Input Username"
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="form-label">Select Role</div>

                                                                    <select
                                                                        className="form-control"
                                                                        name="roleId"
                                                                        onChange={this.handleChangeRole}
                                                                    >
                                                                        <option>Pilih Role</option>
                                                                        {this.state.roles.map((result) => {
                                                                            return (
                                                                                <option onChange={this.onChangeRoleIdId} value={result.id}>{result.name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                {this.state.checkForm == 5 ?

                                                                    <div className="mb-3">
                                                                        <div className="form-label">Pilih Departemen</div>
                                                                        <select
                                                                            className="form-control"
                                                                            name="departementId"
                                                                            onChange={this.handleChange}
                                                                        >
                                                                            <option>Pilih Departemen</option>
                                                                            {this.state.depts.map((result) => {
                                                                                return (
                                                                                    <option value={result.id}>{result.name}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    : null

                                                                }
                                                                <div className="mb-3">
                                                                    <label className="form-label">Password</label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        name="password"
                                                                        placeholder="Input Password"
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Re-Type Password</label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        name="repassword"
                                                                        placeholder="Input Re-Type Password"
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card-footer text-end">
                                                <div className="d-flex">
                                                    <Link className="btn btn-link" to={"/user-management"}>Cancel</Link>
                                                    <button type="submit" className="btn btn-primary ms-auto">Send data</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}
