import React, { Component } from "react";
//jQuery libraries
import 'jquery/dist/jquery.min.js';
import authHeader from "../../../services/auth-header";
import axios from 'axios';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import swal from 'sweetalert';
import $ from 'jquery';
import moment from "moment";
import Select from 'react-select';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import Select2 from "react-validation/build/select"
import http from "../../../services/http";
const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
export default class PaymentSubmission extends Component {
    constructor(props) {
        super(props)
        this.handleOnChangeCreate = this.handleOnChangeCreate.bind(this);
        this.handleSubmitCreate = this.handleSubmitCreate.bind(this);
        this.handleOnChangeCreateFile = this.handleOnChangeCreateFile.bind(this);
        this.handleOnChangeEdit = this.handleOnChangeEdit.bind(this);
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this);

        this.state = {
            data: [],
            depts: [],
            selectedDepts: [],
            departments: [],
            detail: [],
            message: "",
            Documents: [],
            userDept: [],
            documentTypeId: [],
            taxStatusSelect: [],
            taxStatusId: '',
            selectsOptions: [],
            choosenDept: [],
            name: '',
            description: '',
            dueDate: '',
            letterDate: '',
            letterName: '',
            letterNo: '',
        }
    }

    logIdEdit = (id) => {

        this.idEdit = id;
        const idForEdit = this.idEdit;
        axios.get(http.getBaseURL() + '/submissions/payments/' + idForEdit, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data });
            this.setState({
                choosenDept:
                    res.data.data.DepartementReferences?.map(item => {
                        return {
                            value: item.Departement.id,
                            label: item.Departement.name,
                        }
                    })
            })

            const colourOptions = [
                { value: 1, label: 'Ocean' },
                { value: 2, label: 'Blue' },
                { value: 'purple', label: 'Purple', },
            ];

        });
    };

    pdfPath = (path) => {

        this.pdfpath = path;
        console.log(this.pdfpath)
    };

    handleOnChangeCreateDept(e) {
        this.setState({
            departments: e,
            choosenDept: e
        })
    }

    handleOnChangeCreate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleOnChangeCreateTax = (e) => {
        this.setState({
            taxStatusId: e.target.value
        })
    }

    handleOnChangeCreateFile = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }

    handleOnChangeEdit = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmitCreate = event => {
        event.preventDefault();
        this.setState({
            message: "",
        });
        const data = new FormData();
        data.append("name", this.state.name);
        data.append("description", this.state.description);
        data.append("dueDate", this.state.dueDate);
        data.append("letterNo", this.state.letterNo);
        data.append("letterName", this.state.letterName);
        data.append("letterDate", this.state.letterDate);
        data.append("taxStatusId", this.state.taxStatusId);
        this.form.validateAll();
        this.state.departments.map((result, i,) => {
            data.append('departementId', result.value);
        })


        this.state.userDept.map((result, i,) => {
            data.append(result.DocumentType.fieldname, this.state[result.DocumentType.fieldname]);
        })

        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        const url = http.getBaseURL() + "/submissions/payments/v2";
        axios.post(url, data, config)
            .then(res => {
                swal(
                    'Berhasil!',
                    'Anda berhasil menambahkan data!',
                    'success'
                ).then(
                    window.location = "/payment-submission"
                )
            })
            .catch((error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    message: resMessage
                });
            });
    }

    handleSubmitEdit = event => {
        this.setState({
            message: "",
        });
        const idEditShow = this.idEdit;
        event.preventDefault();

        const data = new FormData();
        data.append("name", this.state.name);
        data.append("description", this.state.description);
        data.append("dueDate", this.state.dueDate);
        data.append("letterNo", this.state.letterNo);
        data.append("letterName", this.state.letterName);
        data.append("letterDate", this.state.letterDate);
        data.append("taxStatusId", this.state.taxStatusId);

        this.state.departments.map((result, i,) => {
            data.append('departementId', result.value);
        })


        this.state.userDept.map((result, i,) => {
            data.append(result.DocumentType.fieldname, this.state[result.DocumentType.fieldname]);
        })

        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        const url = http.getBaseURL() + "/submissions/payments/" + idEditShow + "/v2";
        axios.put(url, data, config)
            .then(res => {
                window.location = "/payment-submission" //This line of code will redirect you once the submission is succeed

            })
            .catch((error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    message: resMessage
                });
            });
    }
    detailRow(id, e) {
        this.idStatus = id;

        const IdStatusNow = this.idStatus;
        axios.get(http.getBaseURL() + '/submissions/payments/' + IdStatusNow, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data });
        });

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    deleteRow(id, e) {

        swal({
            title: "Apakah Anda Yakin?",
            text: "Jika anda menghapus data ini tidak akan bisa dikembalikan!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    swal("Data Anda berhasil dihapus", {
                        icon: "success",
                    });
                    axios.delete(http.getBaseURL() + `/submissions/payments/${id}`, { headers: authHeader() })
                        .then(res => {

                            const data = this.state.data.filter(item => item.id !== id);
                            this.setState({ data });
                        })
                } else {
                    swal("Penghapusan Data Dibatalkan!");
                }
            });


    }
    componentDidMount() {



        axios.get(http.getBaseURL() + '/submissions/payments', { headers: authHeader() }).then(res => {
            this.setState({ data: res.data.data.submissions });
        });

        axios.get(http.getBaseURL() + '/configurations/attachments/departements/' + user.departement.id, { headers: authHeader() }).then(res => {
            console.log(res.data.data)
            this.setState({ userDept: res.data.data });
            this.setState({
                message: "",
            });

        });

        axios.get(http.getBaseURL() + '/references/departements', { headers: authHeader() }).then(res => {
            this.setState({ depts: res.data.data });
        });

        axios.get(http.getBaseURL() + '/references/tax-statuses', { headers: authHeader() }).then(res => {
            this.setState({ taxStatusSelect: res.data.data });

        });

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    render() {
        const selectsOptions = this.state.depts.map(item => {
            return {
                value: item.id,
                label: item.name,
            }
        })

        const colourOptions = [
            { value: 1, label: 'Ocean' },
            { value: 2, label: 'Blue' },
            { value: 3, label: 'Purple', },
        ];

        return (

            <>
                <div className="page-wrapper">
                    <div className="container-fluid" >
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">

                                    <div className="page-pretitle">
                                        Pembayaran Tagihan
                                    </div>
                                    <h2 className="page-title">
                                        Pengajuan Pembayaran Tagihan
                                    </h2>
                                </div>

                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">

                                        <a href="#" className="btn btn-primary d-none d-sm-inline-block" data-bs-toggle="modal" data-bs-target="#modal-payment-submission">

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                            Buat Pengajuan Baru
                                        </a>
                                        <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-report" aria-label="Create new report">

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-fluid" style={{ padding: "10px" }}>
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Daftar Pembayaran Tagihan</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3" style={{ overflow: "scroll" }}>
                                            <table id="example" className="table table-hover table-bordered" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Nama Pembayaran</th>
                                                        <th>Tanggal Surat</th>
                                                        <th>Nama Surat</th>
                                                        <th>Nomor Surat</th>
                                                        <th>Tenggat Waktu</th>
                                                        <th>Diajukan oleh</th>
                                                        <th>Status</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.map((result, i, index) => {
                                                        return (

                                                            <tr>
                                                                <td key={i}>{result.name}</td>
                                                                <td>{result.letterDate}</td>
                                                                <td>{result.letterName}</td>
                                                                <td>{result.letterNo}</td>
                                                                <td>{result.dueDate}</td>
                                                                <td>{result.User.name}</td>
                                                                <td className="text-center">
                                                                    <a className="btn btn-primary" style={{ backgroundColor: result.Status.colorHex }}>
                                                                        {result.Status.name}
                                                                    </a>

                                                                </td>
                                                                {result.SubmissionDepartement[0].isCreator == true ? (
                                                                    <td className="text-center">
                                                                        {result.Status.id == 1 &&
                                                                            <div>
                                                                                <button style={{ margin: "0 10px" }} className="btn btn-warning btn-sm  d-sm-inline-block" onClick={() => this.logIdEdit(result.id)} data-bs-toggle="modal" data-bs-target="#modal-payment-edit">
                                                                                    <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ballpen" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M14 6l7 7l-4 4m-11.172 1.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4zm-1.828 1.828l1.768 -1.768"></path>
                                                                                    </svg>
                                                                                </button>
                                                                                <button onClick={(e) => this.deleteRow(result.id, e)} href="#" style={{ margin: "0 10px" }} className="btn btn-danger btn-sm  d-sm-inline-block">
                                                                                    <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-minus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0m6 0l6 0"></path>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                        {result.Status.id == 11 &&
                                                                            <div>
                                                                                <button style={{ margin: "0 10px" }} className="btn btn-warning btn-sm  d-sm-inline-block" onClick={() => this.logIdEdit(result.id)} data-bs-toggle="modal" data-bs-target="#modal-payment-edit">
                                                                                    <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ballpen" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M14 6l7 7l-4 4m-11.172 1.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4zm-1.828 1.828l1.768 -1.768"></path>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        }

                                                                        <button style={{ margin: "0 10px" }} className="btn btn-info btn-sm  d-sm-inline-block" onClick={(e) => this.detailRow(result.id, e)} data-bs-toggle="modal" data-bs-target="#modal-payment-detail">
                                                                            <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </td>
                                                                ) : (
                                                                    <td className="text-center">
                                                                        <button style={{ margin: "0 10px" }} className="btn btn-info btn-sm  d-sm-inline-block" onClick={(e) => this.detailRow(result.id, e)} data-bs-toggle="modal" data-bs-target="#modal-payment-detail">
                                                                            <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </td>
                                                                )}

                                                            </tr>

                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="modal modal-blur fade" id="modal-payment-submission" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Buat Pengajuan Pembayaran Tagihan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <Form onSubmit={this.handleSubmitCreate} className="card"
                                    ref={c => {
                                        this.form = c;
                                    }}
                                >

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Pengajuan Pembayaran Tagihan</label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            placeholder="Masukan Nama Pengajuan Pembayaran Tagihan"
                                                            onChange={this.handleOnChangeCreate}
                                                            validations={[required]}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Deskripsi</label>

                                                        <Textarea
                                                            rows="10"
                                                            type="text"
                                                            className="form-control"
                                                            name="description"
                                                            placeholder="Masukan Deskripsi"
                                                            onChange={this.handleOnChangeCreate}
                                                            validations={[required]}
                                                        >

                                                        </Textarea>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Tenggat Waktu</label>
                                                        <Input
                                                            type="date"
                                                            className="form-control"
                                                            name="dueDate"
                                                            placeholder="Input Due Date"
                                                            onChange={this.handleOnChangeCreate}
                                                            validations={[required]}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Nomor Surat</label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            name="letterNo"
                                                            placeholder="Masukan Nomor Surat"
                                                            onChange={this.handleOnChangeCreate}
                                                            validations={[required]}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Surat</label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            name="letterName"
                                                            placeholder="Masukan Nama Surat"
                                                            onChange={this.handleOnChangeCreate}
                                                            validations={[required]}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Tanggal Surat</label>
                                                        <Input
                                                            type="date"
                                                            className="form-control"
                                                            name="letterDate"
                                                            placeholder="Masukan Tanggal Surat"
                                                            onChange={this.handleOnChangeCreate}
                                                            validations={[required]}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="form-label">Status Pajak</div>

                                                        <Select2
                                                            className="form-control"
                                                            name="taxStatusId"
                                                            onChange={this.handleOnChangeCreate}
                                                            validations={[required]}
                                                        >
                                                            <option value={null}>Pilih Tipe Pembayaran</option>
                                                            {this.state.taxStatusSelect.map((result) => {
                                                                return (
                                                                    <option value={result.id}>{result.name}</option>
                                                                )
                                                            })}
                                                        </Select2>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Bagikan ke :</label>
                                                        <Select
                                                            options={selectsOptions} // set list of the data
                                                            onChange={this.handleOnChangeCreateDept.bind(this)}
                                                            isMulti
                                                        />

                                                    </div>

                                                    {this.state.userDept.map((result, i,) => {
                                                        return (
                                                            <div className="mb-3" key={i}>
                                                                <label className="form-label">{result.DocumentType?.name}</label>

                                                                {result.isRequired == true ? (
                                                                    <Input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name={result.DocumentType.fieldname}
                                                                        placeholder="Upload Document Here"
                                                                        onChange={this.handleOnChangeCreateFile}
                                                                        validations={[required]}
                                                                    />
                                                                ) : (
                                                                    <Input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name={result.DocumentType.fieldname}
                                                                        placeholder="Upload Document Here"
                                                                        onChange={this.handleOnChangeCreateFile}
                                                                    />
                                                                )}

                                                            </div>

                                                        )
                                                    })}






                                                </div>

                                            </div>
                                        </div>

                                    </div>



                                    <div className="modal-footer">
                                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Batalkan
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>

                                        {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.message}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-blur fade " id="modal-payment-detail" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Detail Pengajuan Pembayaran Tagihan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-12 col-xl-12">
                                                    <div className="row">
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Status Pengajuan Pembayaran Tagihan</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Input Payment Name"
                                                                value={this.state.detail?.submission?.Status?.name}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Diajukan Oleh</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Input Payment Name"
                                                                value={this.state.detail?.submission?.User?.name}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Nama Pengajuan Pembayaran Tagihan</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Input Payment Name"
                                                                value={this.state.detail?.submission?.name}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Tenggat Waktu</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="dueDate"
                                                                placeholder="Input Due Date"
                                                                value={this.state.detail?.submission?.dueDate}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Nomor Surat</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="letterNo"
                                                                placeholder="Input Letter No"
                                                                value={this.state.detail?.submission?.letterNo}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Nama Surat</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="letterName"
                                                                placeholder="Input Letter Name"
                                                                value={this.state.detail?.submission?.letterName}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Tanggal Surat</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="letterDate"
                                                                placeholder="Input Date"
                                                                value={this.state.detail?.submission?.letterDate}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Status Pajak </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Input Date"
                                                                value={this.state.detail?.submission?.TaxStatus?.name}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <label className="form-label">Dapat Dilihat Oleh</label>
                                                            <textarea
                                                                rows="1"
                                                                type="text"
                                                                className="form-control"
                                                                name="description"
                                                                placeholder="Input Description"
                                                                value={
                                                                    this.state.detail?.DepartementReferences?.map((result) => {
                                                                        return (
                                                                            result.Departement.name
                                                                        )
                                                                    })
                                                                }
                                                                disabled>
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <label className="form-label">Deskripsi</label>
                                                            <textarea
                                                                rows="5"
                                                                type="text"
                                                                className="form-control"
                                                                name="description"
                                                                placeholder="Input Description"
                                                                value={this.state.detail?.submission?.description}
                                                                disabled>
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {this.state.detail?.submission?.Documents?.map((result) => {
                                                            return (

                                                                <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                                    <label className="form-label">{result.DocType?.name} |
                                                                        {result.filename.length > 30 ?
                                                                            `${result.filename.substring(0, 30)}...` : result.filename
                                                                        }
                                                                    </label>
                                                                    <a style={{ width: "100%" }} className="btn btn-primary  d-sm-inline-block" href={result.path} target="_blank" >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                                                            <path d="M7 11l5 5l5 -5"></path>
                                                                            <path d="M12 4l0 12"></path>
                                                                        </svg>
                                                                        Download File
                                                                    </a>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <label className="form-label">Riwayat Pengajuan</label>
                                                </div>
                                                <div className="card-body">
                                                    <table id="examples" className="table table-hover table-bordered" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Di Approve Oleh</th>
                                                                <th>Status Approval</th>
                                                                <th>Catatan</th>
                                                                <th>Waktu</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.detail?.submission?.Approval?.map((result) => {
                                                                return (

                                                                    <tr>
                                                                        <td>{result.id}</td>
                                                                        <td>{result.ApprovalType.name}</td>
                                                                        <td>
                                                                            {result.approval === true ? (
                                                                                <p>Disetujui</p>
                                                                            ) : (
                                                                                <p>Ditolak</p>
                                                                            )}

                                                                        </td>
                                                                        <td>{result.note}</td>
                                                                        <td>
                                                                            <p>

                                                                                {moment(result.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                                            </p>
                                                                        </td>

                                                                    </tr>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Kembali
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-blur fade " id="modal-payment-edit" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Perbarui Pengajuan Pembayaran Tagihan</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form className="card" onSubmit={this.handleSubmitEdit}>

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Pengajuan Pembayaran Tagihan</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            placeholder="Masukan Nama Pengajuan Pembayaran Tagihan"
                                                            defaultValue={this.state.detail?.submission?.name}
                                                            onChange={this.handleOnChangeEdit}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Deskripsi</label>
                                                        <textarea
                                                            rows="10"
                                                            type="text"
                                                            className="form-control"
                                                            name="description"
                                                            placeholder="Mauskan Deskripsi"
                                                            value={this.state.detail?.submission?.description}
                                                            onChange={this.handleOnChangeEdit}
                                                        >

                                                        </textarea>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Tenggat Waktu</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="dueDate"
                                                            placeholder="Mauskan Tenggat Waktu"
                                                            value={this.state.detail?.submission?.dueDate}
                                                            onChange={this.handleOnChangeEdit}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Nomor Surat</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="letterNo"
                                                            placeholder="Masukan Nomor Surat"
                                                            value={this.state.detail?.submission?.letterNo}
                                                            onChange={this.handleOnChangeEdit}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Surat</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="letterName"
                                                            placeholder="Masukan Nama Surat"
                                                            value={this.state.detail?.submission?.letterName}
                                                            onChange={this.handleOnChangeEdit}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Tanggal Surat</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="letterDate"
                                                            placeholder="Input Date"
                                                            value={this.state.detail?.submission?.letterDate}
                                                            onChange={this.handleOnChangeEdit}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Status Pajak</label>
                                                        <select
                                                            className="form-control"
                                                            name="paymentTypeId"
                                                            onChange={this.handleChangePayment}
                                                            validations={[required]}

                                                        >
                                                            <option value={this.state.detail?.submission?.TaxStatus?.id}>{this.state.detail?.submission?.TaxStatus?.name}</option>
                                                            {this.state.taxStatusSelect.map((result) => {
                                                                return (
                                                                    <option value={result.id}>{result.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Bagikan ke :</label>
                                                        <Select
                                                            value={this.state.choosenDept}
                                                            isMulti
                                                            options={selectsOptions} // set list of the data
                                                            onChange={this.handleOnChangeCreateDept.bind(this)}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                        />
                                                    </div>



                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            {this.state.userDept.map((result, i,) => {
                                                                return (
                                                                    <div className="mb-3" key={i}>
                                                                        <label className="form-label">{result.DocumentType.name}</label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            name={result.DocumentType.fieldname}
                                                                            placeholder="Upload Document Here"
                                                                            onChange={this.handleOnChangeCreateFile}

                                                                        />
                                                                    </div>

                                                                )
                                                            })}

                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            {this.state.detail?.submission?.Documents?.map((result) => {
                                                                return (

                                                                    <div className="mb-3">
                                                                        <label className="form-label">{result.DocType?.name} | {result.filename}</label>
                                                                        <a style={{ width: "100%" }} className="btn btn-primary  d-sm-inline-block" href={result.path} target="_blank" >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                                                                <path d="M7 11l5 5l5 -5"></path>
                                                                                <path d="M12 4l0 12"></path>
                                                                            </svg>
                                                                            Download File
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>

                                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Kembali
                                        </a>

                                        {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.message}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}