import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'



export default function PicRoutes() {
    const user = JSON.parse(localStorage.getItem('user'));
    let userid = user.role.id === 5 || user.role.id === 2 ? true : false;
    return (
        <>
            {userid ? <Outlet /> : <Navigate to="/home" />}
        </>

    )

}