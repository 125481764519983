import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'



export default function PublicRoutes() {
    let userid = localStorage.getItem("user") == null ? true : false;
    return (
        <>
            {userid ? <Outlet /> : <Navigate to="/home" />}
        </>

    )

}

