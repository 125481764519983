import React, { Component } from "react";



export default class Footer extends Component {
    render() {
        return (
            <>
                <footer className="footer footer-transparent d-print-none">
                    <div className="container-xl">
                        <div className=" text-center align-items-center ">
                           
                            
                        </div>
                    </div>
                </footer>
                <footer className="footer footer-transparent d-print-none" style={{ position: "fixed", left: "0", bottom: "0", width: "100%",background: "#e6e7e9", padding: "0.5rem" }}>
                    <div className="container-xl">
                        <div className=" text-center align-items-center ">
                           
                            <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                                <ul className="list-inline list-inline-dots mb-0">
                                    <li className="list-inline-item">
                                        Copyright &copy; 2023 <span> </span>
                                        <a href="." className="link-secondary">JXB Payment Tracking System</a>.
                                        All rights reserved.
                                    </li>
                                  
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </>




        );
    }
}