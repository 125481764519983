import React, { Component } from "react";
//jQuery libraries
import 'jquery/dist/jquery.min.js';
import authHeader from "../../../services/auth-header";
import axios from 'axios';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import swal from 'sweetalert';
import moment from "moment";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import { NumericFormat } from 'react-number-format';
import http from "../../../services/http";
const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
export default class BillSubmission extends Component {
    constructor(props) {
        super(props)
        this.handleOnChangeFile = this.handleOnChangeFile.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnChangeCreateFile = this.handleOnChangeCreateFile.bind(this);
        this.handleSubmitCreate = this.handleSubmitCreate.bind(this);
        this.handleSubmitBillDoc = this.handleSubmitBillDoc.bind(this);
        this.handleAproveDraft = this.handleAproveDraft.bind(this);
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
        this.state = {
            docBill: [],
            data: [],
            detail: [],
            destination: '',
            activity: '',
            invDate: '',
            value: '',
            note: '',
            billDoc: '',
            idStatus: '',
            pdfpath: '',
            idEdit: '',
            message: "",
            invValuePaid: "",
        }
    }
    handleOnChangeCreateFile = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }
    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleOnChangeFile = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }
    handleSubmitBillDoc = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            proofBill: this.state.billDoc,
            invValuePaid: this.state.invValuePaid,
        }
        console.log(data);
        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow;
        axios.post(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/bill-submission"
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleAproveDraft = event => {
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            approval: this.state.approval,
            note: this.state.note,

        }
        console.log(data);
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow + "/approval/user";

        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Approval pada Data Berikut",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Data Anda berhasil diubah Approvalnya", {
                        icon: "success",
                    });
                    axios.post(url, data, config)
                        .then(res => {
                            console.log(res);
                            window.location = "/bill-submission" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Approval Data Dibatalkan!");
                }
            });

    }
    handleSubmitCreate = event => {
        event.preventDefault();
        this.setState({
            message: "",
        });
        const data = new FormData();
        data.append("destination", this.state.destination);
        data.append("activity", this.state.activity);
        data.append("invDate", this.state.invDate);
        data.append("value", this.state.value);
        data.append("note", this.state.note);
        this.form.validateAll();
        this.state.docBill.map((result) => {
            data.append(result.DocumentType.fieldname, this.state[result.DocumentType.fieldname]);
        })

        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        const url = http.getBaseURL() + "/submissions/bills";
        axios.post(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/bill-submission" //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    message: resMessage
                });
            });
    }

    logIdEdit = (id) => {

        this.idEdit = id;
        console.log(this.idEdit);
        const idForEdit = this.idEdit;
        axios.get(http.getBaseURL() + '/submissions/bills/' + idForEdit, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data.submission });
            console.log(res.data.data.submission);
        });
    };

    handleSubmitEdit = event => {
        const idEditShow = this.idEdit;
        event.preventDefault();
        const data = new FormData();
        data.append("destination", this.state.destination);
        data.append("activity", this.state.activity);
        data.append("invDate", this.state.invDate);
        data.append("value", this.state.value);
        data.append("note", this.state.note);

        this.state.docBill.map((result) => {
            data.append(result.DocumentType.fieldname, this.state[result.DocumentType.fieldname]);
        })
        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + idEditShow;
        axios.put(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/bill-submission" //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
            });
    }
    detailRow(id, e) {
        this.idStatus = id;
        const IdStatusNow = this.idStatus;
        console.log(IdStatusNow)
        axios.get(http.getBaseURL() + '/submissions/bills/' + IdStatusNow, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data.submission });
            console.log(res.data.data.submission);
        });
    }

    deleteRow(id, e) {
        swal({
            title: "Apakah Anda Yakin?",
            text: "Jika anda menghapus data ini tidak akan bisa dikembalikan!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    swal("Data Anda berhasil dihapus", {
                        icon: "success",
                    });
                    axios.delete(http.getBaseURL() + `/submissions/bills/${id}`, { headers: authHeader() })
                        .then(res => {
                            console.log(res);
                            console.log(res.data);

                            const data = this.state.data.filter(item => item.id !== id);
                            this.setState({ data });
                        })
                } else {
                    swal("Penghapusan Data Dibatalkan!");
                }
            });


    }
    componentDidMount() {
        const config = {
            headers: {
                "Authorization": user.accessToken,
            },
            params: {
                bill: true,
            }
        };
        axios.get(http.getBaseURL() + '/configurations/attachments/', config).then(res => {
            this.setState({ docBill: res.data.data });
        });

        axios.get(http.getBaseURL() + '/submissions/bills', { headers: authHeader() }).then(res => {
            this.setState({ data: res.data.data.submissions });
            console.log(res.data.data)
        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    render() {
        return (
            <>
                <div className="page-wrapper">
                    <div className="container-fluid" >
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">

                                    <div className="page-pretitle">
                                        Penagihan
                                    </div>
                                    <h2 className="page-title">
                                        Pengajuan Penagihan
                                    </h2>
                                </div>

                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">

                                        <a href="#" className="btn btn-primary d-none d-sm-inline-block" data-bs-toggle="modal" data-bs-target="#modal-bill-submission">

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                            Buat Penagihan Baru
                                        </a>
                                        <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-report" aria-label="Create new report">

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-fluid" style={{ padding: "10px" }}>
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Daftar Penagihan</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3" style={{ overflow: "scroll" }}>
                                            <table id="example" className="table table-hover table-bordered" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Tujuan Penagihan</th>
                                                        <th>Aktivitas</th>
                                                        <th>Tanggal Invoice</th>
                                                        <th>Nominal Tagihan</th>
                                                        <th>Diajukan Oleh</th>
                                                        <th>Status</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.map((result, i) => {
                                                        return (

                                                            <tr>
                                                                <td key={i}>{result.invDestination}</td>
                                                                <td>{result.invActivity}</td>
                                                                <td>{result.invDate}</td>
                                                                <td>
                                                                    <NumericFormat value={result.invValue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                </td>
                                                                <td>{result.User.name}</td>
                                                                <td className="text-center">
                                                                    <a className="btn btn-primary" style={{ backgroundColor: result.Status.colorHex }}>
                                                                        {result.Status.name}
                                                                    </a>
                                                                </td>
                                                                <td className="text-center">
                                                                    {result.Status.id == 1 &&
                                                                        <div>
                                                                            <button onClick={() => this.logIdEdit(result.id)} style={{ margin: "0 10px" }} className="btn btn-warning btn-sm  d-sm-inline-block" data-bs-toggle="modal" data-bs-target="#modal-bill-edit">
                                                                                <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ballpen" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M14 6l7 7l-4 4m-11.172 1.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4zm-1.828 1.828l1.768 -1.768"></path>
                                                                                </svg>

                                                                            </button>
                                                                            <button onClick={(e) => this.deleteRow(result.id, e)} href="#" style={{ margin: "0 10px" }} className="btn btn-danger btn-sm  d-sm-inline-block">
                                                                                <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-minus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0m6 0l6 0"></path>
                                                                                </svg>

                                                                            </button>
                                                                        </div>
                                                                    }

                                                                    {result.Status.id == 12 &&
                                                                        <button onClick={() => this.logIdEdit(result.id)} style={{ margin: "0 10px" }} className="btn btn-warning btn-sm  d-sm-inline-block" data-bs-toggle="modal" data-bs-target="#modal-bill-edit">
                                                                            <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ballpen" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M14 6l7 7l-4 4m-11.172 1.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4zm-1.828 1.828l1.768 -1.768"></path>
                                                                            </svg>

                                                                        </button>
                                                                    }

                                                                    <button style={{ margin: "0 10px" }} className="btn btn-info btn-sm  d-sm-inline-block" onClick={(e) => this.detailRow(result.id, e)} data-bs-toggle="modal" data-bs-target="#modal-bill-detail">
                                                                        <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal modal-blur fade" id="modal-bill-submission" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Buat Pengajuan Penagihan</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <Form onSubmit={this.handleSubmitCreate} className="card"
                                    ref={c => {
                                        this.form = c;
                                    }}
                                >

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Tujuan Penagihan</label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            name="destination"
                                                            placeholder="Masukan Tujuan Penagihan"
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Aktivitas</label>

                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            name="activity"
                                                            placeholder="Masukan Aktivitas"
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        />

                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Tanggal Invoice</label>
                                                        <Input
                                                            type="date"
                                                            className="form-control"
                                                            name="invDate"
                                                            placeholder="Masukan Tanggal Invoice"
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Nominal Tagihan</label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            name="value"
                                                            placeholder="Masukan Nominal Tagihan"
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Catatan</label>
                                                        <Textarea
                                                            rows="5"
                                                            type="text"
                                                            className="form-control"
                                                            name="note"
                                                            placeholder="Masukan Catatan"
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        >
                                                        </Textarea>
                                                    </div>

                                                    {this.state.docBill.map((result, i,) => {
                                                        return (
                                                            <div className="mb-3" key={i}>
                                                                <label className="form-label">{result.DocumentType?.name}</label>
                                                                <Input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name={result.DocumentType.fieldname}
                                                                    placeholder="Input SPP"
                                                                    onChange={this.handleOnChangeFile}
                                                                    validations={[required]}
                                                                />
                                                            </div>

                                                        )
                                                    })}
                                                </div>

                                            </div>
                                        </div>

                                    </div>



                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Kembali
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                        <div className="col-12">
                                            {this.state.message && (
                                                <div className="form-group">
                                                    <div className="alert alert-danger" role="alert">
                                                        {this.state.message}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal modal-blur fade " id="modal-bill-detail" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Detail Pengajuan  Penagihan</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="row">
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Status Pengajuan Penagihan</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail?.Status?.name}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Diajukan Oleh</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail?.User?.name}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Tujuan Penagihan</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail.invDestination}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Aktivitas</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail.invActivity}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Tanggal Invoice</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="dueDate"
                                                            value={this.state.detail.invDate}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Nominal Tagihan</label>
                                                        <NumericFormat style={{ backgroundColor: "#f1f5f9", color: "#626976" }} className="form-control" disabled value={this.state.detail.invValue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="mb-3 col-lg-12">
                                                        <label className="form-label">Catatan</label>
                                                        <textarea
                                                            rows="5"
                                                            type="text"
                                                            className="form-control"
                                                            name="description"
                                                            value={this.state.detail.note}
                                                            disabled
                                                        >
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {this.state.detail?.Documents?.map((result) => {
                                                        return (

                                                            <div className="mb-3 col-lg-6 col-md-12 col-sm-12">
                                                                <label className="form-label">{result.DocType?.name} |
                                                                    {result.filename.length > 30 ?
                                                                        `${result.filename.substring(0, 30)}...` : result.filename
                                                                    }
                                                                </label>
                                                                <a style={{ width: "100%" }} className="btn btn-primary  d-sm-inline-block" href={result.path} target="_blank">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                                                        <path d="M7 11l5 5l5 -5"></path>
                                                                        <path d="M12 4l0 12"></path>
                                                                    </svg>
                                                                    Download File
                                                                </a>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.detail?.Status?.id === 14 &&
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <label className="form-label">Formulir Persetujuan</label>
                                                    </div>
                                                    <div className="card-body">

                                                        <div>

                                                            <form onSubmit={this.handleAproveDraft}>

                                                                <div className="container mt-3">
                                                                    <div className="col-xl-12">
                                                                        <div className="row">
                                                                            <div className="col-md-12 col-xl-12">

                                                                                <div className="mb-3">
                                                                                    <label className="form-label">Lakukan Persetujuan</label>
                                                                                    <select
                                                                                        className="form-control"
                                                                                        name="approval"
                                                                                        onChange={this.handleOnChange}
                                                                                    >
                                                                                        <option>Pilih Opsi</option>
                                                                                        <option value={1}>Setujui</option>
                                                                                        <option value={0}>Tolak</option>

                                                                                    </select>
                                                                                </div>

                                                                                <div className="mb-3">
                                                                                    <label className="form-label">Catatan</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="note"
                                                                                        placeholder="Tambahkan Catatan"
                                                                                        onChange={this.handleOnChange}
                                                                                    />
                                                                                </div>


                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>



                                                                <div class="modal-footer">
                                                                    <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                </div>
                                                            </form>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.detail?.Status?.id === 9 &&
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <label className="form-label">Formulir</label>
                                                    </div>
                                                    <div className="card-body">

                                                        <div>

                                                            <form onSubmit={this.handleSubmitBillDoc} >

                                                                <div className="container mt-3">
                                                                    <div className="col-xl-12">
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-xl-12">

                                                                                <div className="mb-3">
                                                                                    <label className="form-label">Upload Bukti Pembayaran</label>
                                                                                    <input
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        name="billDoc"
                                                                                        placeholder="Input proofDoc"
                                                                                        onChange={this.handleOnChangeCreateFile}
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label className="form-label">Nominal Tagihan</label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        name="invValuePaid"
                                                                                        placeholder="Masukan Nominal Tagihan"
                                                                                        onChange={this.handleOnChange}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                </div>
                                                            </form>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <label className="form-label">Riwayat Pengajuan</label>
                                                </div>
                                                <div className="card-body">
                                                    <table id="example" class="table table-hover table-bordered" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Di Approve Oleh</th>
                                                                <th>Status Approval</th>
                                                                <th>Catatan</th>
                                                                <th>Waktu</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.detail?.Approval?.map((result) => {
                                                                return (

                                                                    <tr>
                                                                        <td>{result.id}</td>
                                                                        <td>{result.ApprovalType.name}</td>
                                                                        <td>
                                                                            {result.approval === true ? (
                                                                                <p>Disetujui</p>
                                                                            ) : (
                                                                                <p>Ditolak</p>
                                                                            )}

                                                                        </td>
                                                                        <td>{result.note}</td>
                                                                        <td>
                                                                            <p>

                                                                                {moment(result.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                                            </p>
                                                                        </td>

                                                                    </tr>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Kembali
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal modal-blur fade " id="modal-bill-edit" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Edit Pengajuan Penagihan</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form className="card" onSubmit={this.handleSubmitEdit}>

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-12 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Tujuan Penagihan</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="destination"
                                                            placeholder="Masukan Tujuan Penagihan"
                                                            defaultValue={this.state.detail.invDestination}
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Aktivitas</label>

                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="activity"
                                                            placeholder="Masukan Aktivitas"
                                                            defaultValue={this.state.detail.invActivity}
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        />

                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Tanggal Invoice</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="invDate"
                                                            placeholder="Masukan Tanggal Invoice"
                                                            defaultValue={this.state.detail.invDate}
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Nominal Tagihan</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="value"
                                                            placeholder="Masukan Nominal Tagihan"
                                                            defaultValue={this.state.detail.invValue}
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Catatan</label>
                                                        <textarea
                                                            rows="5"
                                                            type="text"
                                                            className="form-control"
                                                            name="note"
                                                            placeholder="Masukan Catatan"
                                                            defaultValue={this.state.detail.note}
                                                            onChange={this.handleOnChange}
                                                            validations={[required]}
                                                        >
                                                        </textarea>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            {this.state.docBill.map((result, i,) => {
                                                                return (
                                                                    <div className="mb-3" key={i}>
                                                                        <label className="form-label">{result.DocumentType?.name}</label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            name={result.DocumentType.fieldname}
                                                                            placeholder="Input SPP"
                                                                            onChange={this.handleOnChangeFile}
                                                                            validations={[required]}
                                                                        />
                                                                    </div>

                                                                )
                                                            })}
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            {this.state.detail?.Documents?.map((result) => {
                                                                return (

                                                                    <div className="mb-3">
                                                                        <label className="form-label">{result.DocType?.name} | {result.filename}</label>
                                                                        <a style={{ width: "100%" }} className="btn btn-primary  d-sm-inline-block" href={result.path} target="_blank" >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                                                                <path d="M7 11l5 5l5 -5"></path>
                                                                                <path d="M12 4l0 12"></path>
                                                                            </svg>
                                                                            Download File
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>

                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Kembali
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}