import React, { Component } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

export default class BoardAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: []
    };
  }

  componentDidMount() {
    UserService.getAdminBoard().then(
      response => {
        this.setState({
          content: response.data.data.users,

        });
        console.log(response.data)
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
    const data = this.state.content;
    return (
      <div className="container">
        <header className="jumbotron">
          {data.map(({ name }) => (
            <p key={name}>Coffee type {name}  size.</p>
          ))}
        </header>
      </div>
    );
  }
}
