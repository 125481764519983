import React, { Component } from "react";
import authHeader from "../services/auth-header";
import axios from "axios";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import http from "../services/http";
export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      bills: [],
      bills3: [],
      biilsMgr: [],
      bills7: [],
      bills4: [],
      pengajuan: [],
      verifikator: [],
      reverikator: [],
      mgrperbendaharaan: [],
      remgrperbendaharaan: [],
      smkeu: [],
      resmkeu: [],
      dirkeu: [],
      redirkeu: [],
      spm: [],
      selesai: [],
      penagihan: [],
      bills6: [],
      showPIC: false,
      showMGR: false,
      showMGRA: false,
      showDIR: false,
      showSM: false,
      showVR: false,
      showAdminBoard: false,
      currentUser: undefined,


    };
  }

  componentDidMount() {
    console.log(http.getBaseURL())
    axios.get(http.getBaseURL() + '/histories/payments', { headers: authHeader() }).then(res => {
      this.setState({ spm: res.data.data.submissions });
    });
    axios.get(http.getBaseURL() + '/histories/bills', { headers: authHeader() }).then(res => {
      this.setState({ bills4: res.data.data.submissions });
    });
    axios.get(http.getBaseURL() + '/submissions/payments', { headers: authHeader() }).then(res => {
      this.setState({ pengajuan: res.data.data.submissions.filter(item => item.Status.id == 1) });
      this.setState({ mgrperbendaharaan: res.data.data.submissions.filter(item => item.Status.id == 10) });
      this.setState({ smkeu: res.data.data.submissions.filter(item => item.Status.id == 3) });
      this.setState({ dirkeu: res.data.data.submissions.filter(item => item.Status.id == 5) });
      this.setState({ selesai: res.data.data.submissions.filter(item => item.Status.id == 8) });
      this.setState({ reverikator: res.data.data.submissions.filter(item => item.Status.id == 11) });
      this.setState({ remgrperbendaharaan: res.data.data.submissions.filter(item => item.Status.id == 2) });
      this.setState({ resmkeu: res.data.data.submissions.filter(item => item.Status.id == 4) });
      this.setState({ redirkeu: res.data.data.submissions.filter(item => item.Status.id == 6) });
    });

    axios.get(http.getBaseURL() + '/submissions/bills', { headers: authHeader() }).then(res => {
      this.setState({ bills: res.data.data.submissions.filter(item => item.Status.id == 1) });
      this.setState({ penagihan: res.data.data.submissions.filter(item => item.Status.id == 9) });
      this.setState({ bills3: res.data.data.submissions.filter(item => item.Status.id == 4) });
      this.setState({ biilsMgr: res.data.data.submissions.filter(item => item.Status.id == 12) });
      this.setState({ bills7: res.data.data.submissions.filter(item => item.Status.id == 3) });
      this.setState({ bills6: res.data.data.submissions.filter(item => item.Status.id == 16) });

    });
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showPIC: user.role.name.includes("PIC Bidang/Bagian"),
        showMGR: user.role.name.includes("Perbendaharaan"),
        showMGRA: user.role.name.includes("Mgr Akuntansi & Pajak"),
        showSM: user.role.name.includes("Keuangan"),
        showDIR: user.role.name.includes("Direksi"),
        showVR: user.role.name.includes("Verifikator"),
        showAdminBoard: user.role.name.includes("Administrator"),
      });
    }
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    const { currentUser, showPIC, showAdminBoard, showDIR, showMGR, showSM, showVR, showMGRA } = this.state;

    return (

      <div className="page-wrapper">
        <div className="container-xl">

          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col">
                <h1 className="page-title" style={{ fontWeight: "bold" }}>
                  JXB Payment Tracking: Selamat Datang di Aplikasi JXB Payment Tracking, {currentUser?.name}
                </h1>
              </div>
            </div>
          </div>
        </div>

        {showPIC && (
          <div className="page-body">
            <div className="container-xl">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-xl-12">
                  <div className="row ">
                    <div className="col-12 mt-3 mb-3">
                      <h3>
                        Posisi Pengajuan Pembayaran Tagihan
                      </h3>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.pengajuan.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Persetujuan Mgr Perbendaharaan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.mgrperbendaharaan.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Persetujuan SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.smkeu.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Tanda Tangan DirKeu</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.dirkeu.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh Verifikator</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.reverikator.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">DDitolak Oleh Mgr Perbendaharaan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.remgrperbendaharaan.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.resmkeu.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan yang Selesai</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.spm.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-green" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12 mt-3 mb-3">
                      <h3>
                        Posisi Pengajuan Penagihan
                      </h3>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Persetujuan SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills7?.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Meminta TTD SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills6?.length}

                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Proses Penagihan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.penagihan.length}

                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh MGR Akutansi & Pajak</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.biilsMgr.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills3.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Selesai</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills4.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-green" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        )}

        {showVR && (
          <div className="page-body">
            <div className="container-xl">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-xl-12">
                  <div className="row ">
                    <div className="col-12 mt-3 mb-3">
                      <h3>
                        Posisi Pengajuan Pembayaran Tagihan
                      </h3>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.pengajuan.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">DDitolak Oleh Mgr Perbendaharaan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.remgrperbendaharaan.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        )}
        {showMGRA && (
          <div className="page-body">
            <div className="container-xl">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-xl-12">
                  <div className="row ">
                    <div className="col-12 mt-3 mb-3">
                      <h3>
                        Posisi Pengajuan Penagihan
                      </h3>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Meminta TTD SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills6?.length}

                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills3.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div >
        )}
        {showMGR && (
          <div className="page-body">
            <div className="container-xl">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-xl-12">
                  <div className="row ">
                    <div className="col-12 mt-3 mb-3">
                      <h3>
                        Posisi Pengajuan Pembayaran Tagihan
                      </h3>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Persetujuan Mgr Perbendaharaan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.mgrperbendaharaan.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.resmkeu.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        )}
        {showDIR && (
          <div className="page-body">
            <div className="container-xl">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-xl-12">
                  <div className="row ">
                    <div className="col-12 mt-3 mb-3">
                      <h3>
                        Posisi Pengajuan Pembayaran Tagihan
                      </h3>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.pengajuan.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Persetujuan Mgr Perbendaharaan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.mgrperbendaharaan.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Persetujuan SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.smkeu.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Tanda Tangan DirKeu</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.dirkeu.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh Verifikator</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.reverikator.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">DDitolak Oleh Mgr Perbendaharaan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.remgrperbendaharaan.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.resmkeu.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan yang Selesai</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.spm.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-green" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12 mt-3 mb-3">
                      <h3>
                        Posisi Pengajuan Penagihan
                      </h3>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Persetujuan SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills7?.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Meminta TTD SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills6?.length}

                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Proses Penagihan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.penagihan.length}

                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh MGR Akutansi & Pajak</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.biilsMgr.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Ditolak Oleh SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills3.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-red" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Selesai</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills4.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-green" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        )}

        {showSM && (
          <div className="page-body">
            <div className="container-xl">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-xl-12">
                  <div className="row ">
                    <div className="col-12 mt-3 mb-3">
                      <h3>
                        Posisi Pengajuan Pembayaran Tagihan
                      </h3>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Menunggu Persetujuan SM Keuangan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.smkeu.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-yellow" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan yang Selesai</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.spm.length}
                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-green" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12 mt-3 mb-3">
                      <h3>
                        Posisi Pengajuan Penagihan
                      </h3>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Pengajuan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.bills.length}

                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="subheader">Proses Penagihan</div>
                          </div>
                          <div className="h1 mb-3">
                            {this.state.penagihan.length}

                          </div>
                          <div className="d-flex mb-2">
                          </div>
                          <div className="progress progress-sm">
                            <div className="progress-bar bg-blue" style={{ width: "100%" }} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        )}
      </div >
    );
  }
}
