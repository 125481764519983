import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import { withRouter } from '../common/with-router';

import Logo from './../assets/logo.png'

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      user: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      user: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.user, this.state.password).then(
        () => {
          this.props.router.navigate("/home");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="col-md-12">

        <div className="page page-center">
          <div className="container-tight py-4">
            <div className="text-center mb-4">
              <img src={Logo} alt="JXB Image" height={75} width={75} />
              <h3 style={{ fontWeight: "bold" }}>
                PAYMENT TRACKING SYSTEM
              </h3>
            </div>
            <Form
              className="card card-md"
              style={{ backgroundColor: "#fff", padding: "10px", marginTop: "10px", borderTop: "10px solid #206bc4" }}
              onSubmit={this.handleLogin}
              ref={c => {
                this.form = c;
              }}
            >



              <div className="card-body">
                <h2 className="card-title text-center mb-4">Login to your account</h2>
                <div className="mb-3">
                  <label className="form-label">Email address / Username</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="user"
                    value={this.state.user}
                    onChange={this.onChangeUsername}
                    validations={[required]}
                  />
                </div>
                <div className="mb-2">
                  <label className="form-label">
                    Password

                  </label>
                  <div className="form-label">
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required]}
                    />
                  </div>
                </div>
                <div className="mb-2">

                </div>
                <div className="form-footer">
                  <button
                    className="btn btn-primary btn-block w-100"
                    disabled={this.state.loading}
                    style={{ backgroundColor: "#206bc4", borderColor: "#206bc4" }}
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Login</span>
                  </button>
                </div>
                {this.state.message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {this.state.message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={c => {
                    this.checkBtn = c;
                  }}
                />
              </div>
            </Form>
          </div>
        </div>


      </div>


    );
  }
}

export default withRouter(Login);