import React, { Component } from "react";
//jQuery libraries
import 'jquery/dist/jquery.min.js';
import authHeader from "../../../services/auth-header";
import axios from 'axios';
import AuthService from "../../../services/auth.service";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import swal from 'sweetalert';
import JSZip from "jszip";
import $ from 'jquery';
import moment from "moment";
import http from "../../../services/http";
const user = JSON.parse(localStorage.getItem('user'));
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
window.JSZip = JSZip;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default class DocType extends Component {
    constructor(props) {
        super(props)
        this.handleOnChangeCreate = this.handleOnChangeCreate.bind(this);
        this.handleSubmitCreate = this.handleSubmitCreate.bind(this);
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
        this.state = {
            data: [],
            detail: '',
            idStatus: '',
            idEdit: '',
            message: ""

        }
    }
    handleOnChangeCreate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmitCreate = event => {
        event.preventDefault();
        this.setState({
            message: "",
        });
        const data = {
            name: this.state.name,
        }
        const config = {
            headers: {
                "Authorization": user.accessToken,
            }
        };
        const url = http.getBaseURL() + "/configurations/doctypes";
        axios.post(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/document-type" //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
                const resMessage =
                    error.response.data.message;
                console.log(resMessage);
                this.setState({
                    message: resMessage,
                });
            });
    }

    logIdEdit = (id) => {

        this.idEdit = id;
        console.log(this.idEdit);
        const idForEdit = this.idEdit;
        axios.get(http.getBaseURL() + '/configurations/doctypes/' + idForEdit, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data });
            console.log(res.data.data);
        });
    };



    handleSubmitEdit = event => {
        this.setState({
            message: "",
        });
        const idEditShow = this.idEdit;
        event.preventDefault();
        const data = {
            name: this.state.name,

        }
        const config = {
            headers: {
                "Authorization": user.accessToken,
            }
        };
        const url = http.getBaseURL() + "/configurations/doctypes/" + idEditShow;
        axios.put(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/document-type" //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    message: resMessage
                });
            });
    }

    deleteRow(id, e) {

        swal({
            title: "Apakah Anda Yakin?",
            text: "Jika anda menghapus data ini tidak akan bisa dikembalikan!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    swal("Data Anda berhasil dihapus", {
                        icon: "success",
                    });
                    axios.delete(http.getBaseURL() + `/configurations/doctypes/${id}`, { headers: authHeader() })
                        .then(res => {
                            console.log(res);
                            console.log(res.data);

                            const data = this.state.data.filter(item => item.id !== id);
                            this.setState({ data });
                        })
                } else {
                    swal("Penghapusan Data Dibatalkan!");
                }
            });


    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        axios.get(http.getBaseURL() + '/configurations/doctypes', { headers: authHeader() }).then(res => {
            this.setState({ data: res.data.data });
            console.log(res.data.data);
        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    render() {

        return (
            <>
                <div className="page-wrapper">
                    <div className="container-fluid" >
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">

                                    <div className="page-pretitle">
                                        Konfigurasi Dokumen
                                    </div>
                                    <h2 className="page-title">
                                        Konfigurasi Tipe Dokumen
                                    </h2>
                                </div>

                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">

                                        <a href="#" className="btn btn-primary d-none d-sm-inline-block" data-bs-toggle="modal" data-bs-target="#modal-doc-submission">

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                            Buat Tipe Dokumen Baru
                                        </a>
                                        <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-report" aria-label="Create new report">

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-fluid" style={{ padding: "10px" }}>
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Daftar Tipe Dokumen</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3" style={{ overflow: "scroll" }}>
                                            <table id="example" class="table table-hover table-bordered" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Nama Dokumen</th>
                                                        <th>Nama Pada Formulir Dokumen</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.map((result, i, index) => {
                                                        return (

                                                            <tr>
                                                                <td key={i}>{result.name}</td>
                                                                <td>{result.fieldname}</td>

                                                                <td className="text-center">

                                                                    <div>
                                                                        <button style={{ margin: "0 10px" }} className="btn btn-warning btn-sm  d-sm-inline-block" onClick={() => this.logIdEdit(result.id)} data-bs-toggle="modal" data-bs-target="#modal-doc-edit">
                                                                            <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ballpen" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M14 6l7 7l-4 4m-11.172 1.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4zm-1.828 1.828l1.768 -1.768"></path>
                                                                            </svg>
                                                                        </button>

                                                                        <button onClick={(e) => this.deleteRow(result.id, e)} href="#" style={{ margin: "0 10px" }} className="btn btn-danger btn-sm  d-sm-inline-block">
                                                                            <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-minus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0m6 0l6 0"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="modal modal-blur fade" id="modal-doc-submission" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Buat Tipe Dokumen Baru</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.handleSubmitCreate} className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Tipe Dokumen</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            placeholder="Masukan Nama Pengajuan Pembayaran Tagihan"
                                                            onChange={this.handleOnChangeCreate}
                                                            validations={[required]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {this.state.message && (
                                                    <div className="form-group">
                                                        <div className="alert alert-danger" role="alert">
                                                            {this.state.message}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>



                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Batalkan
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal modal-blur fade" id="modal-doc-edit" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Ubah Tipe Dokumen</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.handleSubmitEdit} className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Nama Tipe Dokumen</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            placeholder="Masukan Nama Pengajuan Pembayaran Tagihan"
                                                            onChange={this.handleOnChangeCreate}
                                                            defaultValue={this.state.detail.name}
                                                            validations={[required]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Batalkan
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}