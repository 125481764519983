import React, { Component, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { Link } from "react-router-dom";
import AuthService from "../../../services/auth.service";

//jQuery libraries
import http from "../../../services/http";
import 'jquery/dist/jquery.min.js';
import authHeader from "../../../services/auth-header";
import axios from 'axios';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};


export default class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            data: [],
            roles: [],
            active: '',
            idStatus: '',

        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    logIdStatus = (id) => {
        this.idStatus = id;
        console.log(this.idStatus)
    };


    handleSubmit = event => {
        const IdStatusNow = this.idStatus;
        const user = JSON.parse(localStorage.getItem('user'));
        event.preventDefault();
        const data = {
            active: this.state.active
        }
        console.log(data);
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/users/" + IdStatusNow + "/status";
        axios.put(url, data, config)
            .then(res => {
                console.log(res);
                window.location = "/user-management    " //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
            });
    }

    deleteRow(id, e) {
        axios.delete(http.getBaseURL() + `/users/${id}`, { headers: authHeader() })
            .then(res => {
                console.log(res);
                console.log(res.data);

                const data = this.state.data.filter(item => item.id !== id);
                this.setState({ data });
            })

    }

    componentDidMount() {
        //Get all users details in bootstrap table
        axios.get(http.getBaseURL() + '/users', { headers: authHeader() }).then(res => {
            //Storing users detail in state array object
            this.setState({ data: res.data.data.users });
            console.log(res.data.data.users);

        });
        axios.get(http.getBaseURL() + '/references/roles').then(res => {
            //Storing users detail in state array object
            this.setState({ roles: res.data.data });
            console.log(res.data.data);

        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    render() {

        return (
            <>
                <div className="page-wrapper">
                    <div className="container-fluid" >
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">

                                    <div className="page-pretitle">
                                        Users
                                    </div>
                                    <h2 className="page-title">
                                        Users Management
                                    </h2>
                                </div>

                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">
                                        <Link className="btn btn-primary d-none d-sm-inline-block" to={"/register-users"} >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                            Create New User
                                        </Link>

                                        <Link className="btn btn-primary d-sm-none btn-icon" aria-label="Create new report">

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="5" y1="12" x2="19" y2="12" /></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-fluid" style={{ padding: "10px" }}>
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">List of Users</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3" style={{ overflow: "scroll" }}>
                                            <table id="example" className="table table-hover table-bordered" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Username</th>
                                                        <th>Role</th>
                                                        <th>Departemen</th>
                                                        <th>Status</th>
                                                        <th>Last Login</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.map((result, i) => {
                                                        return (

                                                            <tr key={i}>
                                                                <td>{result.id}</td>
                                                                <td>{result.username}</td>
                                                                <td>{result.Role?.name}</td>
                                                                <td>{result.Departement?.name}</td>
                                                                <td className="text-center">
                                                                    {result.active == true ? (
                                                                        <a className="btn btn-info btn-sm">Active</a>
                                                                    ) : (
                                                                        <a className="btn btn-info btn-sm">Inactive</a>
                                                                    )}
                                                                </td>
                                                                <td>{result.lastLogin}</td>
                                                                <td className="text-center">


                                                                    <button onClick={(e) => this.deleteRow(result.id, e)} style={{ marginRight: "3px" }} className="btn btn-danger btn-sm d-none d-sm-inline-block" >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-minus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0m6 0l6 0"></path>
                                                                        </svg>
                                                                        Delete User
                                                                    </button>
                                                                    <button style={{ marginLeft: "5px" }} className="btn btn-warning btn-sm d-none d-sm-inline-block" onClick={() => this.logIdStatus(result.id)} data-bs-toggle="modal" data-bs-target="#modal-status">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ballpen" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M14 6l7 7l-4 4"></path>
                                                                            <path d="M5.828 18.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4z"></path>
                                                                            <path d="M4 20l1.768 -1.768"></path>
                                                                        </svg>
                                                                        Edit Status
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal modal-blur fade" id="modal-status" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Change Status</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={this.handleSubmit} className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-6 col-xl-12">

                                                    <div className="mb-3">
                                                        <label className="form-label">Status</label>
                                                        <select
                                                            className="form-control"
                                                            name="active"
                                                            onChange={this.handleChange}
                                                        >
                                                            <option>Pilih Status</option>

                                                            <option value={0}>Inactive</option>
                                                            <option value={1}>Active</option>

                                                        </select>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>

                                    </div>



                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Cancel
                                        </a>
                                        <button type="submit" className="btn btn-primary ms-auto" >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}