import React, { Component } from "react";
import { Routes, Route, } from "react-router-dom";


import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import PicRoutes from "./services/picRoute";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import Navbar from "./components/layouts/navbar.component";
import UserManagement from "./components/pages/userManagement/user-management.component";
import PublicRoutes from "./services/publicRoute";
import PrivateRoutes from "./services/privateRoute";
import AdminRoutes from "./services/adminRoute";
import Footer from "./components/layouts/footer.component";
import PaymentSubmission from "./components/pages/paymentsub/payment-sub.component";
import BillSubmission from "./components/pages/billsub/bill-sub.component";
import RegisterUsers from "./components/pages/userManagement/register-user.component";
import UserProfile from "./components/pages/userProfile/user-profile.component";
import Histories from "./components/pages/history/history.component";
import BillHistories from "./components/pages/history/bill-history.component";
import ApprovePayments from "./components/pages/approve/approvePayment.component";
import ApproveBills from "./components/pages/approve/approveBill.component";
import DirRoutes from "./services/direkturRoute";
import MgrRoutes from "./services/manajerRoute";
import SmRoutes from "./services/smRoute";
import PayHistory from "./services/payHistory";
import BillHistory from "./services/billHistory";
// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import VerifROutes from "./services/verifRoute";
import AttachDoc from "./components/pages/configuration/attach.component";
import DocType from "./components/pages/configuration/doctype.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: Object.values(user.role.id).includes(5),
        showAdminBoard: Object.values(user.role.id).includes(1),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div className="wrapper">
        {currentUser && (
          <Navbar />
        )}

        <div className="mt-3">
          <Routes>
            <Route exact element={<PrivateRoutes />}>
              <Route path="/home" element={<Home />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route exact element={<PicRoutes />}>
                <Route path="/payment-submission" element={<PaymentSubmission />} />
                <Route path="/bill-submission" element={<BillSubmission />} />
              </Route>
              <Route exact element={<MgrRoutes />}>
                <Route path="/approve-payment-submission" element={<ApprovePayments />} />
              </Route>
              <Route exact element={<VerifROutes />}>
                <Route path="/document-type" element={<DocType />} />
                <Route path="/attachment-assignment" element={<AttachDoc />} />
              </Route>
              <Route exact element={<SmRoutes />}>
                <Route path="/bill-submission" element={<BillSubmission />} />
                <Route path="/approve-bill-submission" element={<ApproveBills />} />
              </Route>
              <Route exact element={<AdminRoutes />}>
                <Route path="/admin" element={<BoardAdmin />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/register-users" element={<RegisterUsers />} />
              </Route>
              <Route exact element={<BillHistory />}>
                <Route path="/bill-histories" element={< BillHistories />} />
              </Route>
              <Route exact element={<PayHistory />}>
                <Route path="/payment-histories" element={< Histories />} />
              </Route>

            </Route>
            <Route exact element={<PublicRoutes />}>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
            </Route>
          </Routes>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
