import React, { Component } from "react";
//jQuery libraries
import 'jquery/dist/jquery.min.js';
import authHeader from "../../../services/auth-header";
import axios from 'axios';
import AuthService from "../../../services/auth.service";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import swal from 'sweetalert';
import $ from 'jquery';
import moment from "moment";
import http from "../../../services/http";
const user = JSON.parse(localStorage.getItem('user'));
export default class ApprovePayments extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChangeCreateFile = this.handleOnChangeCreateFile.bind(this);
        this.handleSubmitSM = this.handleSubmitSM.bind(this);
        this.handleSubmitVR = this.handleSubmitVR.bind(this);
        this.handleSubmitDIR = this.handleSubmitDIR.bind(this);
        this.handleSubmitSPM = this.handleSubmitSPM.bind(this);
        this.state = {
            data: [],
            detail: [],
            approval: '',
            note: '',
            idStatus: '',
            showMGR: false,
            showDIR: false,
            showSM: false,
            spmUpload: '',
        }
    }
    handleOnChangeCreateFile = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }
    logIdStatus = (id) => {
        this.idStatus = id;
        console.log(this.idStatus)
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = event => {
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            approval: this.state.approval,
            note: this.state.note,

        }
        console.log(data);
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/submissions/payments/" + IdStatusNow + "/approval/mgr-perbendaharaan";

        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Approval pada Data Berikut",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Data Anda berhasil diubah Approvalnya", {
                        icon: "success",
                    });
                    axios.post(url, data, config)
                        .then(res => {
                            console.log(res);
                            window.location = "/approve-payment-submission" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Approval Data Dibatalkan!");
                }
            });

    }
    handleSubmitVR = event => {
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            approval: this.state.approval,
            note: this.state.note,

        }
        console.log(data);
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/submissions/payments/" + IdStatusNow + "/approval/verifikator";

        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Approval pada Data Berikut",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Data Anda berhasil diubah Approvalnya", {
                        icon: "success",
                    });
                    axios.post(url, data, config)
                        .then(res => {
                            console.log(res);
                            window.location = "/approve-payment-submission" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Approval Data Dibatalkan!");
                }
            });

    }

    handleSubmitSM = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            approval: this.state.approval,
            note: this.state.note,

        }
        console.log(data);
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/submissions/payments/" + IdStatusNow + "/approval/sm-keuangan";

        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Approval pada Data Berikut",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Data Anda berhasil diubah Approvalnya", {
                        icon: "success",
                    });
                    axios.post(url, data, config)
                        .then(res => {
                            console.log(res);
                            window.location = "/approve-payment-submission" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Approval Data Dibatalkan!");
                }
            });

    }

    handleSubmitSPM = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            spm: this.state.spmUpload,

        }
        console.log(data);
        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"

            }
        };
        const url = http.getBaseURL() + "/submissions/payments/" + IdStatusNow + "/spm";

        axios.post(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/approve-payment-submission" //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
            });

    }
    handleSubmitDIR = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            approval: this.state.approval,
            note: this.state.note,

        }
        console.log(data);
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/submissions/payments/" + IdStatusNow + "/approval/direktur-keuangan";

        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Approval pada Data Berikut",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Data Anda berhasil diubah Approvalnya", {
                        icon: "success",
                    });
                    axios.post(url, data, config)
                        .then(res => {
                            console.log(res);
                            window.location = "/approve-payment-submission" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Approval Data Dibatalkan!");
                }
            });

    }



    handleOnChangeFile = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }


    detailRow(id, e) {
        this.idStatus = id;
        const IdStatusNow = this.idStatus;
        console.log(IdStatusNow)
        axios.get(http.getBaseURL() + '/submissions/payments/' + IdStatusNow, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data });
            console.log(res.data.data);
        });
    }
    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
                showPIC: user.role.name.includes("PIC Bidang/Bagian"),
                showMGR: user.role.name.includes("Mgr Perbendaharaan"),
                showMGA: user.role.name.includes("Mgr Akuntansi & Pajak"),
                showSM: user.role.name.includes("SM Keuangan"),
                showDIR: user.role.name.includes("Direksi"),
                showVR: user.role.name.includes("Verifikator"),
                showAdminBoard: user.role.name.includes("Administrator"),
            });
        }

        axios.get(http.getBaseURL() + '/submissions/payments', { headers: authHeader() }).then(res => {

            this.setState({ data: res.data.data.submissions });
            console.log(res.data.data.submissions);
        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    render() {
        const { currentUser, showPIC, showAdminBoard, showDIR, showMGR, showSM, showVR } = this.state;
        return (
            <>
                <div className="page-wrapper">
                    <div className="container-fluid" >
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">

                                    <div className="page-pretitle">
                                        Pembayaran Tagihan
                                    </div>
                                    <h2 className="page-title">
                                        Pengajuan Pembayaran Tagihan
                                    </h2>
                                </div>

                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-fluid" >
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Daftar Pengajuan Pembayaran Tagihan</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3" style={{ overflow: "scroll" }}>
                                            <table id="example" class="table table-hover table-bordered" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Nama Pengajuan</th>
                                                        <th>Tanggal SUrat</th>
                                                        <th>Nama Surat</th>
                                                        <th>Nomor Surat</th>
                                                        <th>Tenggat Waktu</th>
                                                        <th>Jumlah Dokumen</th>
                                                        <th>Diajukan Oleh</th>
                                                        <th>Komentar Terakhir </th>
                                                        <th>Status</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state?.data.map((result, i) => {
                                                        return (

                                                            <tr>
                                                                <td key={i}>{result.name}</td>
                                                                <td>{result.letterDate}</td>
                                                                <td>{result.letterName}</td>
                                                                <td>{result.letterNo}</td>
                                                                <td>{result.dueDate}</td>
                                                                <td>{result.Documents.length}</td>
                                                                <td>{result.User.name}</td>
                                                                <td>{result.Approval.slice(-1)[0]?.note}</td>
                                                                <td className="text-center">
                                                                    <a className="btn btn-primary" style={{ backgroundColor: result.Status.colorHex }}>
                                                                        {result.Status.name}
                                                                    </a>

                                                                </td>
                                                                <td className="text-center">


                                                                    <button style={{ margin: "0 10px" }} className="btn btn-info btn-sm  d-sm-inline-block" onClick={(e) => this.detailRow(result.id, e)} data-bs-toggle="modal" data-bs-target="#modal-payment-detail">
                                                                        <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                                        </svg>
                                                                    </button>

                                                                </td>
                                                            </tr>

                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal modal-blur fade " id="modal-payment-detail" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Detail Pengajuan Pembayaran Tagihan</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-md-12 col-xl-12">
                                                    <div className="row">
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Status Pengajuan Pembayaran Tagihan</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Input Payment Name"
                                                                value={this.state.detail?.submission?.Status?.name}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Diajukan Oleh</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Input Payment Name"
                                                                value={this.state.detail?.submission?.User?.name}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Nama Pengajuan Pembayaran Tagihan</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Input Payment Name"
                                                                value={this.state.detail?.submission?.name}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Tenggat Waktu</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="dueDate"
                                                                placeholder="Input Due Date"
                                                                value={this.state.detail?.submission?.dueDate}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Nomor Surat</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="letterNo"
                                                                placeholder="Input Letter No"
                                                                value={this.state.detail?.submission?.letterNo}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Letter Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="letterName"
                                                                placeholder="Input Letter Name"
                                                                value={this.state.detail?.submission?.letterName}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Tanggal Surat</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="letterDate"
                                                                placeholder="Input Date"
                                                                value={this.state.detail?.submission?.letterDate}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                            <label className="form-label">Status Pajak </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={this.state.detail?.submission?.TaxStatus?.name}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <label className="form-label">Deskripsi</label>
                                                            <textarea
                                                                rows="5"
                                                                type="text"
                                                                className="form-control"
                                                                name="description"
                                                                placeholder="Input Description"
                                                                value={this.state.detail?.submission?.description}
                                                                disabled
                                                            >

                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {this.state.detail?.submission?.Documents?.map((result) => {
                                                            return (

                                                                <div className="mb-3 col-6">
                                                                    <label className="form-label">{result.DocType?.name} |
                                                                        {result.filename.length > 30 ?
                                                                            `${result.filename.substring(0, 30)}...` : result.filename
                                                                        }
                                                                    </label>
                                                                    <a style={{ width: "100%" }} className="btn btn-primary  d-sm-inline-block" href={result.path} target="_blank">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                                                            <path d="M7 11l5 5l5 -5"></path>
                                                                            <path d="M12 4l0 12"></path>
                                                                        </svg>
                                                                        Download File
                                                                    </a>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <label className="form-label">Formulir Persetujuan</label>
                                                </div>
                                                <div className="card-body">

                                                    {showSM && (
                                                        <form onSubmit={this.handleSubmitSM}>

                                                            <div className="container mt-3">
                                                                <div className="col-xl-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6 col-xl-12">

                                                                            <div className="mb-3">
                                                                                <label className="form-label">Lakukan Persetujuan</label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="approval"
                                                                                    onChange={this.handleChange}
                                                                                >
                                                                                    <option>Pilih Opsi</option>
                                                                                    <option value={1}>Setujui</option>
                                                                                    <option value={0}>Tolak</option>

                                                                                </select>
                                                                            </div>

                                                                            <div className="mb-3">
                                                                                <label className="form-label">Catatan</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="note"
                                                                                    placeholder="Tambahkan Catatan"
                                                                                    onChange={this.handleChange}
                                                                                />
                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>



                                                            <div class="modal-footer">
                                                                <button type="submit" className="btn btn-primary ms-auto" >Submit</button>
                                                            </div>
                                                        </form>
                                                    )}
                                                    {showMGR && (
                                                        <div>
                                                            {this.state.detail?.submission?.Status?.id === 5 ? (
                                                                <form onSubmit={this.handleSubmitSPM} >

                                                                    <div className="container mt-3">
                                                                        <div className="col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-xl-12">

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Upload SPM</label>
                                                                                        <input
                                                                                            type="file"
                                                                                            className="form-control"
                                                                                            name="spmUpload"
                                                                                            placeholder="Input spmUpload"
                                                                                            onChange={this.handleOnChangeCreateFile}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>



                                                                    <div class="modal-footer">
                                                                        <button type="submit" className="btn btn-primary ms-auto" >Submit</button>
                                                                    </div>
                                                                </form>
                                                            ) : (
                                                                <form onSubmit={this.handleSubmit} >

                                                                    <div className="container mt-3">
                                                                        <div className="col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-xl-12">

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Lakukan Persetujuan</label>
                                                                                        <select
                                                                                            className="form-control"
                                                                                            name="approval"
                                                                                            onChange={this.handleChange}
                                                                                        >
                                                                                            <option>Pilih Opsi</option>
                                                                                            <option value={1}>Setujui</option>
                                                                                            <option value={0}>Tolak</option>

                                                                                        </select>
                                                                                    </div>

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Catatan</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="note"
                                                                                            placeholder="Tambahkan Catatan"
                                                                                            onChange={this.handleChange}
                                                                                        />
                                                                                    </div>


                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>



                                                                    <div class="modal-footer">
                                                                        <button type="submit" className="btn btn-primary ms-auto" >Submit</button>
                                                                    </div>

                                                                </form>
                                                            )}
                                                        </div>

                                                    )}


                                                    {showVR && (
                                                        <form onSubmit={this.handleSubmitVR} >

                                                            <div className="container mt-3">
                                                                <div className="col-xl-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6 col-xl-12">

                                                                            <div className="mb-3">
                                                                                <label className="form-label">Lakukan Persetujuan</label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="approval"
                                                                                    onChange={this.handleChange}
                                                                                >
                                                                                    <option>Pilih Opsi</option>
                                                                                    <option value={1}>Setujui</option>
                                                                                    <option value={0}>Tolak</option>

                                                                                </select>
                                                                            </div>

                                                                            <div className="mb-3">
                                                                                <label className="form-label">Catatan</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="note"
                                                                                    placeholder="Tambahkan Catatan"
                                                                                    onChange={this.handleChange}
                                                                                />
                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>



                                                            <div class="modal-footer">
                                                                <button type="submit" className="btn btn-primary ms-auto" >Submit</button>
                                                            </div>
                                                        </form>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card mt-3">
                                                <div className="card-header">
                                                    <label className="form-label">Riwayat Pengajuan</label>
                                                </div>
                                                <div className="card-body">
                                                    <table id="example" class="table table-hover table-bordered" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Di Approve Oleh</th>
                                                                <th>Status Approval</th>
                                                                <th>Catatan</th>
                                                                <th>Waktu</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.detail?.submission?.Approval?.map((result) => {
                                                                return (

                                                                    <tr>
                                                                        <td>{result.id}</td>
                                                                        <td>{result.ApprovalType.name}</td>
                                                                        <td>
                                                                            {result.approval === true ? (
                                                                                <p>Disetujui</p>
                                                                            ) : (
                                                                                <p>Ditolak</p>
                                                                            )}

                                                                        </td>
                                                                        <td>{result.note}</td>
                                                                        <td>
                                                                            <p>

                                                                                {moment(result.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                                            </p>
                                                                        </td>

                                                                    </tr>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Kembali
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}