import React, { Component } from "react";
import AuthService from "../../../services/auth.service";
//jQuery libraries
import 'jquery/dist/jquery.min.js';
import authHeader from "../../../services/auth-header";
import axios from 'axios';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import swal from 'sweetalert';
import $ from 'jquery';
import moment from "moment";
import { NumericFormat } from 'react-number-format';
import http from "../../../services/http";
const user = JSON.parse(localStorage.getItem('user'));

export default class ApproveBills extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.handleOnChangeCreateFile = this.handleOnChangeCreateFile.bind(this);
        this.handleUpdateSM = this.handleUpdateSM.bind(this);
        this.handleUpdateMGRA = this.handleUpdateMGRA.bind(this);
        this.handleApproveSM = this.handleApproveSM.bind(this);
        this.handleSubmitDOC = this.handleSubmitDOC.bind(this);
        this.handleSubmitDraft = this.handleSubmitDraft.bind(this);
        this.handleSubmitInvoice = this.handleSubmitInvoice.bind(this);
        this.handleEditInvoice = this.handleEditInvoice.bind(this);
        this.handleUpdateDraft = this.handleUpdateDraft.bind(this);
        this.state = {
            data: [],
            detail: [],
            approval: '',
            note: '',
            idStatus: '',
            showSM: false,
            showMGRA: false,
            proofDoc: '',
            DraftInvoice: '',
            DocumentInvoice: '',
        }
    }
    handleEditInvoice = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            DocumentInvoice: this.state.DocumentInvoice,
        }
        console.log(data);
        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow + "/invoice";
        axios.put(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/approve-bill-submission"
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleSubmitInvoice = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            DocumentInvoice: this.state.DocumentInvoice,
        }
        console.log(data);
        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow + "/invoice";
        axios.post(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/approve-bill-submission"
            })
            .catch((error) => {
                console.log(error);
            });
    }
    handleApproveSM = event => {
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            approval: this.state.approval,
            note: this.state.note,

        }
        console.log(data);
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow + "/approval/sm-keuangan";

        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Approval pada Data Berikut",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Data Anda berhasil diubah Approvalnya", {
                        icon: "success",
                    });
                    axios.post(url, data, config)
                        .then(res => {
                            console.log(res);
                            window.location = "/approve-bill-submission" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Approval Data Dibatalkan!");
                }
            });

    }
    handleOnChangeCreateFile = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }
    handleUpdateDraft = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            DocumentDraftInvoice: this.state.DraftInvoice,
        }
        console.log(data);
        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow + "/draft-invoice";
        axios.put(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/approve-bill-submission"
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleSubmitDraft = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            DocumentDraftInvoice: this.state.DraftInvoice,
        }
        console.log(data);
        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow + "/draft-invoice";
        axios.post(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/approve-bill-submission"
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleSubmitDOC = event => {
        const user = JSON.parse(localStorage.getItem('user'));
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            proofBill: this.state.proofDoc,

        }
        console.log(data);
        const config = {
            headers: {
                "Authorization": user.accessToken,
                "Content-Type": "multipart/form-data"

            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow;

        axios.post(url, data, config)
            .then(res => {
                console.log(res);
                console.log(res.data.status);
                window.location = "/approve-bill-submission" //This line of code will redirect you once the submission is succeed
            })
            .catch((error) => {
                console.log(error);
            });

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    detailRow(id, e) {
        this.idStatus = id;
        const IdStatusNow = this.idStatus;
        console.log(IdStatusNow)
        axios.get(http.getBaseURL() + '/submissions/bills/' + IdStatusNow, { headers: authHeader() }).then(res => {
            this.setState({ detail: res.data.data.submission });
            console.log(res.data.data.submission);
        });
    }
    handleUpdateSM = event => {
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            approval: this.state.approval,
            note: this.state.note,

        }
        console.log(data);
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow + "/status/";

        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Approval pada Data Berikut",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Data Anda berhasil diubah Approvalnya", {
                        icon: "success",
                    });
                    axios.put(url, data, config)
                        .then(res => {
                            console.log(res);
                            window.location = "/approve-bill-submission" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Approval Data Dibatalkan!");
                }
            });

    }

    handleUpdateMGRA = event => {
        const IdStatusNow = this.idStatus;
        event.preventDefault();
        const data = {
            approval: this.state.approval,
            note: this.state.note,

        }
        console.log(data);
        const config = {
            headers: {
                'Authorization': user.accessToken
            }
        };
        const url = http.getBaseURL() + "/submissions/bills/" + IdStatusNow + "/approval/mgr-akuntansi";

        swal({
            title: "Apakah Anda Yakin?",
            text: "Melakukan Approval pada Data Berikut",
            icon: "warning",
            buttons: true,
            buttons: ['Batalkan', 'Ya'],
            dangerMode: true,
        })
            .then((willRecord) => {
                if (willRecord) {
                    swal("Data Anda berhasil diubah Approvalnya", {
                        icon: "success",
                    });
                    axios.post(url, data, config)
                        .then(res => {
                            console.log(res);
                            window.location = "/approve-bill-submission" //This line of code will redirect you once the submission is succeed
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    swal("Approval Data Dibatalkan!");
                }
            });

    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                showSM: user.role.name.includes("Keuangan"),
                showMGRA: user.role.name.includes("Mgr Akuntansi & Pajak"),
            });
        }
        axios.get(http.getBaseURL() + '/submissions/bills', { headers: authHeader() }).then(res => {
            this.setState({ data: res.data.data.submissions });
            console.log(res.data.data.submissions);
        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    render() {
        const { showSM, showMGRA } = this.state;

        return (
            <>
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col">

                                    <div className="page-pretitle">
                                        Penagihan
                                    </div>
                                    <h2 className="page-title">
                                        Pengajuan Penagihan
                                    </h2>
                                </div>

                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="container-fluid" >
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Daftar Penagihan</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3" style={{ overflow: "scroll" }}>
                                            <table id="example" class="table table-hover table-bordered" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Tujuan Penagihan</th>
                                                        <th>Aktivitas</th>
                                                        <th>Tanggal Invoice</th>
                                                        <th>Nominal Tagihan</th>
                                                        <th>Jumlah Dokumen</th>
                                                        <th>Diajukan Oleh</th>
                                                        <th>Komentar Terakhir</th>
                                                        <th>Status</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.map((result, i) => {
                                                        return (

                                                            <tr>
                                                                <td key={i}>{result.invDestination}</td>
                                                                <td>{result.invActivity}</td>
                                                                <td>{result.invDate}</td>
                                                                <td>
                                                                    <NumericFormat value={result.invValue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                </td>
                                                                <td>{result.Documents.length}</td>
                                                                <td>{result.User.name}</td>
                                                                <td>{result.Approval.slice(-1)[0]?.note}</td>
                                                                <td className="text-center">
                                                                    <a className="btn btn-primary" style={{ backgroundColor: result.Status.colorHex }}>
                                                                        {result.Status.name}
                                                                    </a>
                                                                </td>
                                                                <td className="text-center">


                                                                    <button style={{ margin: "0 10px" }} className="btn btn-info btn-sm  d-sm-inline-block" onClick={(e) => this.detailRow(result.id, e)} data-bs-toggle="modal" data-bs-target="#modal-payment-detail">
                                                                        <svg style={{ margin: "0" }} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                                        </svg>
                                                                    </button>

                                                                </td>
                                                            </tr>

                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal modal-blur fade " id="modal-payment-detail" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Detail Pengajuan  Penagihan</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="card">

                                    <div className="container mt-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="row">
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Status Pengajuan Penagihan</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail?.Status?.name}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Diajukan Oleh</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail?.User?.name}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Tujuan Penagihan</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail.invDestination}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Aktivitas</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            value={this.state.detail.invActivity}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Tanggal Invoice</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="dueDate"
                                                            value={this.state.detail.invDate}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <label className="form-label">Nominal Tagihan</label>
                                                        <NumericFormat style={{ backgroundColor: "#f1f5f9", color: "#626976" }} className="form-control" disabled value={this.state.detail.invValue} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />

                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="mb-3 col-lg-12">
                                                        <label className="form-label">Catatan</label>
                                                        <textarea
                                                            rows="5"
                                                            type="text"
                                                            className="form-control"
                                                            name="description"
                                                            value={this.state.detail.note}
                                                            disabled
                                                        >
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {this.state.detail?.Documents?.map((result) => {
                                                        return (

                                                            <div className="mb-3 col-lg-6 col-md-12 col-sm-12">
                                                                <label className="form-label">{result.DocType?.name} |
                                                                    {result.filename.length > 30 ?
                                                                        `${result.filename.substring(0, 30)}...` : result.filename
                                                                    }
                                                                </label>
                                                                <a style={{ width: "100%" }} className="btn btn-primary  d-sm-inline-block" href={result.path} target="_blank">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                                                        <path d="M7 11l5 5l5 -5"></path>
                                                                        <path d="M12 4l0 12"></path>
                                                                    </svg>
                                                                    Download File
                                                                </a>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <label className="form-label">Formulir Persetujuan</label>
                                                </div>
                                                <div className="card-body">

                                                    {showMGRA && (
                                                        <div>
                                                            {this.state.detail?.Status?.id === 1 &&
                                                                <form onSubmit={this.handleUpdateMGRA}>

                                                                    <div className="container mt-3">
                                                                        <div className="col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-md-12 col-xl-12">

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Lakukan Persetujuan</label>
                                                                                        <select
                                                                                            className="form-control"
                                                                                            name="approval"
                                                                                            onChange={this.handleChange}
                                                                                        >
                                                                                            <option>Pilih Opsi</option>
                                                                                            <option value={1}>Setujui</option>
                                                                                            <option value={0}>Tolak</option>

                                                                                        </select>
                                                                                    </div>

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Catatan</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="note"
                                                                                            placeholder="Tambahkan Catatan"
                                                                                            onChange={this.handleChange}
                                                                                        />
                                                                                    </div>


                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>



                                                                    <div class="modal-footer">
                                                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                    </div>
                                                                </form>
                                                            }

                                                            {this.state.detail?.Status?.id === 4 &&
                                                                <form onSubmit={this.handleUpdateMGRA}>

                                                                    <div className="container mt-3">
                                                                        <div className="col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-md-12 col-xl-12">

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Lakukan Persetujuan</label>
                                                                                        <select
                                                                                            className="form-control"
                                                                                            name="approval"
                                                                                            onChange={this.handleChange}
                                                                                        >
                                                                                            <option>Pilih Opsi</option>
                                                                                            <option value={1}>Setujui</option>
                                                                                            <option value={0}>Tolak</option>

                                                                                        </select>
                                                                                    </div>

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Catatan</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="note"
                                                                                            placeholder="Tambahkan Catatan"
                                                                                            onChange={this.handleChange}
                                                                                        />
                                                                                    </div>


                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>



                                                                    <div class="modal-footer">
                                                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                    </div>
                                                                </form>
                                                            }
                                                            {this.state.detail?.Status?.id === 13 &&
                                                                <>
                                                                    {this.state.detail?.Documents.filter(item => item.DocType.name == "Draft Invoice") ?
                                                                        <form onSubmit={this.handleUpdateDraft} >

                                                                            <div className="container mt-3">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 col-xl-12">

                                                                                            <div className="mb-3">
                                                                                                <label className="form-label">Update Draft Invoice</label>
                                                                                                <input
                                                                                                    type="file"
                                                                                                    className="form-control"
                                                                                                    name="DraftInvoice"
                                                                                                    placeholder="Input proofDoc"
                                                                                                    onChange={this.handleOnChangeCreateFile}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="modal-footer">
                                                                                <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                            </div>
                                                                        </form>
                                                                        :
                                                                        <form onSubmit={this.handleSubmitDraft} >

                                                                            <div className="container mt-3">
                                                                                <div className="col-xl-12">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 col-xl-12">

                                                                                            <div className="mb-3">
                                                                                                <label className="form-label">Upload Draft Invoice</label>
                                                                                                <input
                                                                                                    type="file"
                                                                                                    className="form-control"
                                                                                                    name="DraftInvoice"
                                                                                                    placeholder="Input proofDoc"
                                                                                                    onChange={this.handleOnChangeCreateFile}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="modal-footer">
                                                                                <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                            </div>
                                                                        </form>
                                                                    }
                                                                </>
                                                            }
                                                            {this.state.detail?.Status?.id === 16 &&
                                                                <form onSubmit={this.handleSubmitInvoice} >

                                                                    <div className="container mt-3">
                                                                        <div className="col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-xl-12">

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Upload Invoice</label>
                                                                                        <input
                                                                                            type="file"
                                                                                            className="form-control"
                                                                                            name="DocumentInvoice"
                                                                                            placeholder="Input proofDoc"
                                                                                            onChange={this.handleOnChangeCreateFile}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                    </div>
                                                                </form>
                                                            }
                                                            {this.state.detail?.Status?.id === 9 &&
                                                                <form onSubmit={this.handleEditInvoice} >

                                                                    <div className="container mt-3">
                                                                        <div className="col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-xl-12">

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Update Invoice</label>
                                                                                        <input
                                                                                            type="file"
                                                                                            className="form-control"
                                                                                            name="DocumentInvoice"
                                                                                            placeholder="Input proofDoc"
                                                                                            onChange={this.handleOnChangeCreateFile}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                    </div>
                                                                </form>
                                                            }

                                                            {this.state.detail?.Status?.id === 15 &&
                                                                <form onSubmit={this.handleUpdateDraft} >

                                                                    <div className="container mt-3">
                                                                        <div className="col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-xl-12">

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Update Draft Invoice</label>
                                                                                        <input
                                                                                            type="file"
                                                                                            className="form-control"
                                                                                            name="DraftInvoice"
                                                                                            placeholder="Input proofDoc"
                                                                                            onChange={this.handleOnChangeCreateFile}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                    </div>
                                                                </form>
                                                            }


                                                        </div>
                                                    )}
                                                    {showSM && (
                                                        <div>
                                                            {this.state.detail?.Status?.id === 3 &&
                                                                <form onSubmit={this.handleApproveSM}>

                                                                    <div className="container mt-3">
                                                                        <div className="col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-md-12 col-xl-12">

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Lakukan Persetujuan</label>
                                                                                        <select
                                                                                            className="form-control"
                                                                                            name="approval"
                                                                                            onChange={this.handleChange}
                                                                                        >
                                                                                            <option>Pilih Opsi</option>
                                                                                            <option value={1}>Setujui</option>
                                                                                            <option value={0}>Tolak</option>

                                                                                        </select>
                                                                                    </div>

                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Catatan</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="note"
                                                                                            placeholder="Tambahkan Catatan"
                                                                                            onChange={this.handleChange}
                                                                                        />
                                                                                    </div>


                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>



                                                                    <div class="modal-footer">
                                                                        <button type="submit" className="btn btn-primary ms-auto" >Kirim</button>
                                                                    </div>
                                                                </form>
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <label className="form-label">Riwayat Pengajuan</label>
                                                </div>
                                                <div className="card-body">
                                                    <table id="example" class="table table-hover table-bordered" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Di Approve Oleh</th>
                                                                <th>Status Approval</th>
                                                                <th>Catatan</th>
                                                                <th>Waktu</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.detail?.Approval?.map((result) => {
                                                                return (

                                                                    <tr>
                                                                        <td>{result.id}</td>
                                                                        <td>{result.ApprovalType.name}</td>
                                                                        <td>
                                                                            {result.approval === true ? (
                                                                                <p>Disetujui</p>
                                                                            ) : (
                                                                                <p>Ditolak</p>
                                                                            )}

                                                                        </td>
                                                                        <td>{result.note}</td>
                                                                        <td>
                                                                            <p>

                                                                                {moment(result.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                                                                            </p>
                                                                        </td>

                                                                    </tr>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                                            Kembali
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}