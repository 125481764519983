class http {
  getBaseURL() {
    let url;
    switch (process.env.NODE_ENV) {
      case "production":
        url = "https://payment-track-api.jxboard.id";
        break;
      case "development":
      default:
        url = "https://payment-track-api.jxboard.id";
    }

    return url;
  }
}

export default new http();
